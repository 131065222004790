import {
  CellValueChangedEvent,
  ICellRendererParams,
  RowDragEndEvent,
  RowDragEnterEvent,
  ValueFormatterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiOutlineTrash } from "react-icons/hi";
import { MdOutlineContentCopy } from "react-icons/md";
import { useSplashMessage } from "src/hook/useSplashMessage";
import {
  QuotationDetailInitialValueParam,
  QuotationDetailInitialValueParamSub,
} from "src/model/param/QuotationDetailInitialValueParam";

import { useAuthUserContext } from "../../context/AuthUser";
import { useDialog } from "../../context/DialogProvider";
import useApi from "../../hook/useApi";
import { QuotationsPostRequest } from "../../model/api/request/QuotationsPostRequest";
import { QuotationsPostRequestSub } from "../../model/api/request/QuotationsPostRequestSub";
import { OrderGetResponse } from "../../model/api/response/OrderGetResponse";
import { OrderGetResponseSub } from "../../model/api/response/OrderGetResponseSub";
import { ProductGetResponse } from "../../model/api/response/ProductGetResponse";
import { ProductsGetResponse } from "../../model/api/response/ProductsGetResponse";
import { QuotationGetResponse } from "../../model/api/response/QuotationGetResponse";
import { QuotationGetResponseSub } from "../../model/api/response/QuotationGetResponseSub";
import {
  AgDatePicker,
  agNumberFormatter,
  agNumberSetter,
  agProductNameFormatter,
  defaultColDef,
} from "../../util/AgGridUtil";
import {
  OrderFactor,
  OrderStatus,
  ProductCodeConst,
  QuotationStatus,
} from "../../util/Constant";
import { calculateTax } from "../../util/TaxUtil";
import { formatDate, isEnglish } from "../../util/ecUtil";
import S3Uploader, { S3UploaderHandles } from "../common/S3Uploader";
import ProductListModal, {
  ProductListModalHandles,
} from "../setting/ProductListModal";
import OrderDetail, { OrderDetailHandles, OrderParam } from "./OrderDetail";
import OrderDetailDelivery from "./OrderDetailDelivery";
import OrderDetailHeader, {
  OrderDetailChangeEvent,
  OrderDetailType,
} from "./OrderDetailHeader";
import OrderDetailPrice from "./OrderDetailPrice";

interface FormValues {
  statusClassValue: string;
  quotationClassValue: string | undefined;
  companyCode: string;
  companyName: string | undefined;
  imoNo: string;
  vesselName: string | undefined;
  vesselBuilder: string | undefined;
  yardNo: string | undefined;
  requestDeliveryDate: string | undefined;
  customerQuotationNo1: string | undefined;
  customerQuotationNo2: string | undefined;
  requestReplyDeadline: string | undefined;
  bemacStartFlg: boolean;
  deliveryName: string;
  deliveryPostalCode: string;
  deliveryAddress: string;
  deliveryTel: string;
  dispatchFlg: boolean;
  dispatchPlace: string | undefined;
  dispatchDate: string | undefined;
  quotationUserId: number | undefined;
  quotationUserName: string | undefined;
  quotationComment: string | undefined;
  quotationDateTime: string | undefined;
  replyUserId: number | undefined;
  replyUserName: string | undefined;
  replyComment: string | undefined;
  replyDateTime: string | undefined;
  replyDeliveryDate: string | undefined;
  replyInstallmentDeliveryFlg: boolean;
  tempSubtotalPrice: number;
  replySubtotalPrice: number | undefined;
  replyDeliveryPrice: number | undefined;
  replyDiscountPrice: number | undefined;
  replyTax: number | undefined;
  replyTaxPrice: number | undefined;
  replyTotalPrice: number | undefined;
  checkUserId: number | undefined;
  checkUserName: string | undefined;
  checkDateTime: string | undefined;
  s3Prefix: string | undefined;
  updateDateTime: string | undefined;
  dockName: string | undefined;
  dockScheduleStart: string | undefined;
  dockScheduleEnd: string | undefined;
  problemProduct: string | undefined;
  problemDetail: string | undefined;
  reQuotationFlg: boolean;
}
// 明細データの型
type FormRowValues = {
  key: number;
  isDbRow: boolean;
  quotationDetailNo: number;
  productCode: string;
  productGroupName?: string;
  productGroupNameEn?: string;
  spec?: string;
  standardUnitPrice?: number;
  quantity?: number;
  unit?: string;
  note?: string;
  detailSubTotal?: number;
  replyUnitCost?: number;
  replyQuantity?: number;
  replyDeliveryDate?: string;
  replyDetailSubTotal?: number;
  referenceDetailNo?: number;
  createUserId?: number;
  createDateTime?: string;
  updateUserId?: number;
  updateDateTime?: string;
  addsByBemac: boolean;
  existFlg: boolean;
};

type ShowParam = {
  quotationNo?: number;
  initialData?: QuotationDetailInitialValueParam;
  maintenanceScheduleKey?: MaintenanceScheduleKey;
  recommendKey?: RecommendKey;
};

// 公開する関数の定義
export interface QuotationDetailHandles {
  show(param: ShowParam): void;
}

type saveButtonName =
  | "一時保存"
  | "見積依頼"
  | "見積回答"
  | "見積キャンセル"
  | "見積依頼変更"
  | "再見積依頼"
  | "見積否認"
  | "見積注文";
type Props = {
  onClickSaveButton?: (buttonName: saveButtonName) => void;
  quotationOrderHistoryFlg?: boolean;
};
type MaintenanceScheduleKey = {
  companyCode: string;
  imoNo: string;
  inspectionYearClassValue: string;
};
type RecommendKey = {
  recommendId: number;
};

// エリアごとのCSSクラス
const areaClass = "p-1 bg-white b-detail-items";
const textAreaClass =
  "form-control textarea b-input-text-square b-input-comment-height";

/** 見積詳細コンポーネント */
const QuotationDetail = forwardRef<QuotationDetailHandles, Props>(
  (props: Props, ref) => {
    // ダイアログ使用宣言
    const showDialog = useDialog();
    //スプラッシュメッセージ使用宣言
    const splashMessage = useSplashMessage();
    // API使用宣言
    const api = useApi();
    // ユーザ情報
    const auth = useAuthUserContext();
    // 翻訳
    const { t: tc } = useTranslation("QuotationDetail");

    // state宣言
    const [quotationNo, setQuotationNo] = useState<number | undefined>();
    const [maintenanceScheduleKey, setMaintenanceScheduleKey] = useState<
      MaintenanceScheduleKey | undefined
    >();
    const [recommendKey, setRecommendKey] = useState<
      RecommendKey | undefined
    >();
    const [show, setShow] = useState(false);

    //クリップボードにコピー関数
    const copyToClipboard = async () => {
      //　明細の内容をコピー
      let copyText =
        "No" +
        "	" +
        "品目コード" +
        "	" +
        "品名" +
        "	" +
        "型式" +
        "	" +
        "単価" +
        "	" +
        "依頼数量" +
        "	" +
        "単位" +
        "	" +
        "小計" +
        "	" +
        "明細備考" +
        "	" +
        "単価(BEMAC回答)" +
        "	" +
        "数量(BEMAC回答)" +
        "	" +
        "小計(BEMAC回答)" +
        "\n";
      inpDetails.forEach((inpData) => {
        copyText += (inpData.quotationDetailNo ?? "") + "	";
        copyText += (inpData.productCode ?? "") + "	";
        copyText +=
          (isEnglish()
            ? inpData.productGroupNameEn
            : inpData.productGroupName ?? "") + "	";
        copyText += (inpData.spec ?? "") + "	";
        copyText += (inpData.standardUnitPrice ?? "") + "	";
        copyText += (inpData.quantity ?? "") + "	";
        copyText += (inpData.unit ?? "") + "	";
        copyText += (inpData.detailSubTotal ?? "") + "	";
        copyText += (inpData.note ?? "") + "	";
        copyText += (inpData.replyUnitCost ?? "") + "	";
        copyText += (inpData.replyQuantity ?? "") + "	";
        copyText += (inpData.replyDetailSubTotal ?? "") + "	";
        copyText += "\n";
      });
      await global.navigator.clipboard.writeText(copyText);
    };

    // 入力項目
    const [inpQuotation, setInpQuotation] = useState<FormValues>(
      generateDraftQuotation()
    );
    // 入力項目：明細
    const [inpDetails, setInpDetails] = useState<FormRowValues[]>([]);

    // ユーザ入力有無
    const [formChanged, setFormChanged] = useState<boolean>(false);
    // 読み取り専用フラグ
    const [readOnly, setReadOnly] = useState(false);

    // 参照
    const gridRef = useRef<AgGridReact>(null); // 明細
    const productListModalRef = useRef<ProductListModalHandles>(null); // 商品一覧
    const uploaderCustomerRef = useRef<S3UploaderHandles>(null); // ファイル添付（顧客）
    const uploaderBemacRef = useRef<S3UploaderHandles>(null); // ファイル添付（BEMAC）
    const orderDetailRef = useRef<OrderDetailHandles>(null); // 注文詳細

    function generateDraftQuotation(): FormValues {
      return {
        statusClassValue: QuotationStatus.DRAFT,
        quotationClassValue: OrderFactor.EC_PARTS,
        companyCode: auth.user().bemacFlg ? "" : auth.user().companyCode,
        companyName: auth.user().bemacFlg ? "" : auth.user().companyName,
        imoNo: "",
        vesselName: "",
        vesselBuilder: "",
        yardNo: "",
        requestDeliveryDate: "",
        customerQuotationNo1: "",
        customerQuotationNo2: "",
        requestReplyDeadline: "",
        bemacStartFlg: auth.user().bemacFlg,
        deliveryName: "",
        deliveryPostalCode: "",
        deliveryAddress: "",
        deliveryTel: "",
        dispatchFlg: false,
        dispatchPlace: "",
        dispatchDate: "",
        quotationUserId: auth.user().bemacFlg ? undefined : auth.user().userId,
        quotationUserName: auth.user().bemacFlg
          ? undefined
          : auth.user().userName,
        quotationComment: "",
        quotationDateTime: "",
        replyUserId: auth.user().bemacFlg ? auth.user().userId : undefined,
        replyUserName: auth.user().bemacFlg ? auth.user().userName : undefined,
        replyComment: "",
        replyDateTime: "",
        replyDeliveryDate: "",
        replyInstallmentDeliveryFlg: false,
        tempSubtotalPrice: 0,
        replySubtotalPrice: undefined,
        replyDeliveryPrice: undefined,
        replyDiscountPrice: 0,
        replyTax: 0,
        replyTaxPrice: undefined,
        replyTotalPrice: undefined,
        checkUserId: undefined,
        checkUserName: undefined,
        checkDateTime: "",
        updateDateTime: "",
        dockName: "",
        dockScheduleStart: "",
        dockScheduleEnd: "",
        s3Prefix: generateS3Prefix(),
        problemProduct: undefined,
        problemDetail: undefined,
        reQuotationFlg: false,
      };
    }

    // S3に登録するオブジェクトキーのプリフィクスを採番
    function generateS3Prefix() {
      const datetime = formatDate(new Date(), "YYYYMMDDHHmmss");
      return `order/${datetime}-${auth.user().userId}/`;
    }

    // 公開する関数の実装
    useImperativeHandle(ref, () => ({
      show(param: ShowParam) {
        // 初期化
        setFormChanged(false);
        setQuotationNo(param.quotationNo);
        setMaintenanceScheduleKey(param.maintenanceScheduleKey);
        setRecommendKey(param.recommendKey);
        if (param.quotationNo) {
          // 見積番号が設定されていたらAPIから初期化する
          api
            .get<QuotationGetResponse>(
              `/api/v1/quotations/${param.quotationNo}`
            )
            .then((response) => {
              const newQuotation = mapQuotationGetResponseToFormValues(
                response.data
              );

              // LCMの場合再見積依頼時に更新明細値がわたってくるので、セットする
              if (
                response.data.quotationClassValue === OrderFactor.LCM_PARTS &&
                param.initialData
              ) {
                const initialDetails = param.initialData?.details ?? [];
                // 更新値で渡されている品目の情報を取得しておく
                getProductDatas(initialDetails).then((productDatas) => {
                  // 明細初期値（渡されていなければ0行の配列）
                  const detailRows: FormRowValues[] =
                    mapInitialValueParamSubToFormRowValues(
                      initialDetails,
                      productDatas
                    );
                  // stateに保存してモーダルを表示状態にする
                  saveInitialDataAndShow(newQuotation, detailRows);
                });
              } else {
                const detailRows = mapQuotationGetResponseSubToFormRowValues(
                  response.data.quotationDetail,
                  true
                );
                // stateに保存してモーダルを表示状態にする
                saveInitialDataAndShow(newQuotation, detailRows);
              }
            })
            .catch((error) => showDialog({ error }));
        } else {
          const initialDetails = param.initialData?.details ?? [];
          // 初期値で渡されている品目の情報を取得しておく
          getProductDatas(initialDetails).then((productDatas) => {
            // 新規作成の場合、デフォルト値を設定
            const newQuotation = generateDraftQuotation();
            // 見積種別区分コードを設定
            if (param.maintenanceScheduleKey) {
              newQuotation.quotationClassValue = OrderFactor.LCM_PARTS;
            } else if (param.recommendKey) {
              newQuotation.quotationClassValue = OrderFactor.LCM_RECOMMEND;
            }
            // 初期値が渡されていたら設定する
            if (param.initialData) {
              setFromInitalData(newQuotation, param.initialData);
            }
            // 明細初期値（渡されていなければ0行の配列）
            const detailRows: FormRowValues[] =
              mapInitialValueParamSubToFormRowValues(
                initialDetails,
                productDatas
              );
            // stateに保存してモーダルを表示状態にする
            saveInitialDataAndShow(newQuotation, detailRows);
          });
        }
      },
    }));

    /** 見積取得APIのレスポンスをFormValuesに変換 */
    function mapQuotationGetResponseToFormValues(src: QuotationGetResponse) {
      const mappedQuotation: FormValues = {
        statusClassValue: src.statusClassValue,
        quotationClassValue: src.quotationClassValue ?? undefined,
        companyCode: src.companyCode,
        companyName: src.companyName ?? undefined,
        imoNo: src.imoNo,
        vesselName: src.vesselName ?? undefined,
        vesselBuilder: src.vesselBuilder ?? undefined,
        yardNo: src.yardNo ?? undefined,
        requestDeliveryDate: src.requestDeliveryDate ?? undefined,
        customerQuotationNo1: src.customerQuotationNo1 ?? undefined,
        customerQuotationNo2: src.customerQuotationNo2 ?? undefined,
        requestReplyDeadline: src.requestReplyDeadline ?? undefined,
        bemacStartFlg: src.bemacStartFlg,
        deliveryName: src.deliveryName,
        deliveryPostalCode: src.deliveryPostalCode,
        deliveryAddress: src.deliveryAddress,
        deliveryTel: src.deliveryTel,
        dispatchFlg: src.dispatchFlg,
        dispatchPlace: src.dispatchPlace ?? undefined,
        dispatchDate: src.dispatchDate ?? undefined,
        quotationUserId: src.quotationUserId,
        quotationUserName: src.quotationUserName,
        quotationComment: src.quotationComment ?? undefined,
        quotationDateTime: src.quotationDateTime ?? undefined,
        replyUserId: src.replyUserId ?? undefined,
        replyUserName: src.replyUserName ?? undefined,
        replyComment: src.replyComment ?? undefined,
        replyDateTime: src.replyDateTime ?? undefined,
        replyDeliveryDate: src.replyDeliveryDate ?? undefined,
        replyInstallmentDeliveryFlg: src.replyInstallmentDeliveryFlg,
        tempSubtotalPrice: src.tempSubtotalPrice,
        replySubtotalPrice: src.replySubtotalPrice ?? undefined,
        replyDeliveryPrice: src.replyDeliveryPrice ?? undefined,
        replyDiscountPrice: src.replyDiscountPrice ?? undefined,
        replyTax: src.replyTax ?? undefined,
        replyTaxPrice: src.replyTaxPrice ?? undefined,
        replyTotalPrice: src.replyTotalPrice ?? undefined,
        checkUserId: src.checkUserId ?? undefined,
        checkUserName: src.checkUserName ?? undefined,
        checkDateTime: src.checkDateTime ?? undefined,
        // s3Prefixが付いてなければ付けなおす
        s3Prefix: src.s3Prefix ?? generateS3Prefix(),
        updateDateTime: src.updateDateTime ?? undefined,
        dockName: src.dockName ?? undefined,
        dockScheduleStart: src.dockScheduleStart ?? undefined,
        dockScheduleEnd: src.dockScheduleEnd ?? undefined,
        problemProduct: src.problemProduct ?? undefined,
        problemDetail: src.problemDetail ?? undefined,
        reQuotationFlg: false,
      };
      return mappedQuotation;
    }

    /** 見積取得APIのレスポンスをFormRowValuesに変換 */
    function mapQuotationGetResponseSubToFormRowValues(
      src: QuotationGetResponseSub[],
      isDb: boolean
    ) {
      const mappedRows: FormRowValues[] = [];
      src.forEach((it, index) => {
        mappedRows.push({
          key: index,
          isDbRow: isDb,
          quotationDetailNo: it.quotationDetailNo,
          productCode: it.productCode,
          productGroupName: it.productGroupName,
          productGroupNameEn: it.productGroupNameEn,
          spec: it.spec ?? undefined,
          standardUnitPrice: it.standardUnitPrice ?? undefined,
          quantity: it.quantity,
          unit: it.unit ?? undefined,
          note: it.note ?? undefined,
          replyUnitCost: it.replyUnitCost ?? undefined,
          replyQuantity: it.replyQuantity ?? undefined,
          replyDeliveryDate: it.replyDeliveryDate ?? undefined,
          replyDetailSubTotal: it.replyDetailSubTotal ?? undefined,
          detailSubTotal: (it.standardUnitPrice ?? 0) * (it.quantity ?? 0),
          referenceDetailNo: it.referenceDetailNo ?? undefined,
          createUserId: it.createUserId ?? undefined,
          createDateTime: it.createDateTime ?? undefined,
          updateUserId: it.updateUserId ?? undefined,
          updateDateTime: it.updateDateTime ?? undefined,
          addsByBemac: it.addsByBemac,
          existFlg: true,
        });
      });
      return mappedRows;
    }

    /** 遷移パラメータの初期値をFormValuesに上書きする */
    function setFromInitalData(
      newQuotation: FormValues,
      initialData: QuotationDetailInitialValueParam
    ) {
      // 得意先コード
      newQuotation.companyCode =
        initialData.companyCode ?? newQuotation.companyCode;
      // 得意先名
      newQuotation.companyName =
        initialData.companyName ?? newQuotation.companyName;
      // IMO
      newQuotation.imoNo = initialData.imoNo ?? newQuotation.imoNo;
      // 船名
      newQuotation.vesselName =
        initialData.vesselName ?? newQuotation.vesselName;
      // 建造造船所
      newQuotation.vesselBuilder =
        initialData.vesselBuilder ?? newQuotation.vesselBuilder;
      // 造船所番号
      newQuotation.yardNo = initialData.yardNo ?? newQuotation.yardNo;
      // 技師派遣フラグ
      newQuotation.dispatchFlg =
        initialData.dispatchFlg ?? newQuotation.dispatchFlg;
      // 入渠ドック名
      newQuotation.dockName = initialData.dockName ?? newQuotation.dockName;
      // 入渠ドック予定開始日
      newQuotation.dockScheduleStart =
        initialData.dockScheduleStart ?? newQuotation.dockScheduleStart;
      // 入渠ドック予定終了日
      newQuotation.dockScheduleEnd =
        initialData.dockScheduleEnd ?? newQuotation.dockScheduleEnd;
    }

    /** 遷移パラメータの初期値をFormRowValuesに変換 */
    function mapInitialValueParamSubToFormRowValues(
      src: QuotationDetailInitialValueParamSub[],
      productDatas: ProductGetResponse[]
    ) {
      const mappedRows: FormRowValues[] = [];
      src.forEach((it, index) => {
        // 商品情報を特定
        const productData = productDatas.find(
          (data) => data.productCode === it.productCode
        );

        mappedRows.push({
          key: index,
          isDbRow: false,
          quotationDetailNo: index + 1,
          productCode: it.productCode,
          productGroupName: it.productGroupName
            ? it.productGroupName
            : productData?.productGroupName,
          productGroupNameEn: it.productGroupNameEn
            ? it.productGroupNameEn
            : productData?.productGroupNameEn,
          spec: it.spec ? it.spec : productData?.spec,
          standardUnitPrice: productData?.unitPrice,
          quantity: undefined,
          unit: productData?.unit,
          note: productData?.note,
          replyUnitCost: it.unitPrice ?? productData?.unitPrice,
          replyQuantity: it.quantity,
          replyDeliveryDate: undefined,
          replyDetailSubTotal: it.unitPrice
            ? (it.unitPrice ?? 0) * (it.quantity ?? 0)
            : (productData?.unitPrice ?? 0) * (it.quantity ?? 0),
          detailSubTotal: (productData?.unitPrice ?? 0) * (it.quantity ?? 0),
          referenceDetailNo: it.referenceDetailNo,
          addsByBemac: auth.user().bemacFlg,
          existFlg: true,
        });
      });
      return mappedRows;
    }

    /** 商品情報を複数取得 */
    async function getProductDatas(
      details: QuotationDetailInitialValueParamSub[]
    ) {
      // 品目コードを収集（空文字、空行コードは除く）
      const productCodes = details
        .filter(
          (it) =>
            it.productCode.length > 0 &&
            it.productCode !== ProductCodeConst.BLANK
        )
        .map((it) => it.productCode);
      // 重複を除く
      const distinctProductCodes = Array.from(new Set(productCodes));
      const promises = distinctProductCodes.map((productCode) =>
        api
          .get<ProductGetResponse>(`/api/v1/products/${productCode}`)
          .then((it) => {
            return it.data;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              return undefined;
            }
            throw error;
          })
      );
      const datas = await Promise.all(promises);
      // undefinedを取り除いて返す
      return datas.filter(
        (it): it is Exclude<typeof it, undefined> => typeof it !== "undefined"
      );
    }

    // stateに保存してモーダルを表示状態にする
    function saveInitialDataAndShow(
      quotation: FormValues,
      detailRows: FormRowValues[]
    ) {
      // stateに保存
      setInpQuotation(quotation);

      // 行追加可能時は新規行を追加
      // 技師以外で作成中・依頼中は追加可能
      // LCMの場合はBEMACのみ可（諸経費入力のため）
      if (
        !auth.isEngineer() &&
        [QuotationStatus.DRAFT, QuotationStatus.REQUESTED].includes(
          quotation.statusClassValue
        ) &&
        (quotation.quotationClassValue !== OrderFactor.LCM_PARTS ||
          auth.user().bemacFlg)
      ) {
        //Bemacユーザで技師派遣フラグONかつ(依頼中またはLCM/レコメンドから遷移時)
        //かつ、経費行が存在しない場合空白行と経費行を追加する
        if (
          auth.user().bemacFlg &&
          quotation.dispatchFlg &&
          (QuotationStatus.REQUESTED === quotation.statusClassValue ||
            (quotation.quotationClassValue &&
              [OrderFactor.LCM_PARTS, OrderFactor.LCM_RECOMMEND].includes(
                quotation.quotationClassValue
              ))) &&
          canAddExpensesRow(detailRows)
        ) {
          if (canAddReadyRow([...inpDetails], quotation.quotationClassValue)) {
            addNewRow(detailRows, [ProductCodeConst.BLANK, "", ""]);
            setInpDetails(detailRows);
            addReadyRow();
            addExpensesRow();
          } else {
            addNewRow(detailRows, [ProductCodeConst.BLANK, ""]);
            setInpDetails(detailRows);
            addExpensesRow();
          }
        } else {
          addNewRow(detailRows);
          setInpDetails(detailRows);
        }
      } else {
        setInpDetails(detailRows);
      }

      // 仮見積金額再計算
      calculateTempPrice(detailRows);
      // 作成中、依頼中は金額計算
      if (
        [QuotationStatus.DRAFT, QuotationStatus.REQUESTED].includes(
          quotation.statusClassValue
        )
      ) {
        calculateReplyPrice(detailRows);
      }

      // 読み取り専用モードの設定
      setReadOnlyMode(
        quotation.statusClassValue,
        quotation.companyCode,
        quotation.quotationUserId
      );

      // 表示
      setShow(true);
    }

    // 読み取り専用モードの設定
    function setReadOnlyMode(
      statusClassValue: string,
      companyCode: string,
      quotationUserId: number | undefined
    ) {
      // 技師は読み取り専用
      if (auth.isEngineer()) {
        setReadOnly(true);
        return;
      }

      // 成約、取消、否成約は読み取り専用
      if (
        [
          QuotationStatus.ACCEPTED,
          QuotationStatus.DELETED,
          QuotationStatus.DENIED,
        ].includes(statusClassValue)
      ) {
        setReadOnly(true);
        return;
      }

      // 顧客の場合は管理者か自身が依頼者でなければ読み取り専用
      if (
        !auth.user().bemacFlg &&
        quotationUserId &&
        auth.user().userId !== quotationUserId &&
        !auth.isCustomerAdmin()
      ) {
        setReadOnly(true);
        return;
      }

      // その他は編集可能
      setReadOnly(false);
    }

    // モーダル表示ハンドラ
    const handleShow = () => {
      // アップローダーの初期化
      uploaderCustomerRef.current?.init(
        `${inpQuotation.s3Prefix}customer/`,
        false
      );
      uploaderBemacRef.current?.init(`${inpQuotation.s3Prefix}bemac/`, false);
    };

    // モーダルクローズハンドラ
    const handleClose = () => {
      if (formChanged) {
        showDialog({
          id: "I001",
          confirm: true,
          callback(isOk) {
            if (isOk) {
              setInpDetails([]);
              setShow(false);
            }
          },
        });
      } else {
        setInpDetails([]);
        setShow(false);
      }
    };

    // 保存系ボタン(メッセージ)
    function handleClickActionButton(name: saveButtonName) {
      // 明細の編集を停止
      gridRef.current?.api.stopEditing();

      if (name === "見積注文") {
        // 注文画面を表示
        const orderDetails = inpQuotationDetailToOrder();
        const order = inpQuotationToOrder(orderDetails);
        orderDetailRef.current?.show({
          quotationData: order,
          customerQuotationNo1: inpQuotation.customerQuotationNo1,
          customerQuotationNo2: inpQuotation.customerQuotationNo2,
        });
        return;
      }

      if (name === "見積依頼" && inpQuotation.requestReplyDeadline) {
        const currentDateTime = parseInt(formatDate(new Date(), "YYYYMMDD"));
        const requestReplyDeadline: number = parseInt(
          inpQuotation.requestReplyDeadline?.replaceAll(/-/g, "")
        );
        if (requestReplyDeadline < currentDateTime + 2) {
          // 確認ダイアログを表示
          showDialog({
            id: "E033",
            args: [tc("見積依頼")],
            confirm: true,
            callback(isOk) {
              if (isOk) {
                saveData(name);
              }
            },
          });
        } else {
          showConfirmMsg(name);
        }
      } else if (
        name === "見積回答" &&
        (inpQuotation.replyDiscountPrice === 0 || inpQuotation.replyTax === 0)
      ) {
        // 割引額もしくは消費税額が0だったら確認メッセージを表示
        showDialog({
          id: "E085",
          args: ["見積回答"],

          confirm: true,
          callback(isOk) {
            if (isOk) {
              showConfirmMsg(name);
            }
          },
        });
      } else {
        showConfirmMsg(name);
      }
    }

    function showConfirmMsg(name: saveButtonName) {
      // 確認メッセージ
      const messageId = confirmMessageId(name);
      if (messageId) {
        showDialog({
          id: messageId,
          confirm: true,
          callback: (isOk) => {
            if (isOk) {
              if (name === "再見積依頼") {
                // モード切替
                setInpQuotation((prev) => ({
                  ...prev,
                  reQuotationFlg: true,
                  statusClassValue: QuotationStatus.DRAFT,
                  quotationDateTime: undefined,
                }));
                setInpDetails((prev) => {
                  const rows = prev.map((row) => {
                    return {
                      ...row,
                      createUserId: undefined,
                      createDateTime: undefined,
                      updateUserId: undefined,
                      updateDateTime: undefined,
                      addsByBemac: auth.user().bemacFlg,
                      standardUnitPrice:
                        ProductCodeConst.DELIVERY_PRICE_CODES.includes(
                          row.productCode ?? ""
                        )
                          ? row.replyUnitCost
                          : row.standardUnitPrice,
                      quantity: ProductCodeConst.DELIVERY_PRICE_CODES.includes(
                        row.productCode ?? ""
                      )
                        ? row.replyQuantity
                        : row.quantity,
                      detailSubTotal:
                        ProductCodeConst.DELIVERY_PRICE_CODES.includes(
                          row.productCode ?? ""
                        )
                          ? row.replyDetailSubTotal
                          : row.detailSubTotal,
                    };
                  });
                  if (
                    OrderFactor.LCM_PARTS !== inpQuotation.quotationClassValue
                  ) {
                    // LCM以外は明細に入力用の新規行追加
                    addNewRow(rows);
                  }
                  // 仮見積金額再計算
                  calculateTempPrice(rows);
                  // 合計金額計算
                  calculateReplyPrice(rows);
                  return rows;
                });
              } else {
                saveData(name);
              }
            }
          },
        });
      } else {
        // メッセージなしで保存
        saveData(name);
      }
    }

    function inpQuotationDetailToOrder(): OrderGetResponseSub[] {
      return inpDetails
        .filter((it) => it.productCode)
        .map((row) => {
          return {
            orderDetailNo: row.quotationDetailNo,
            productCode: row.productCode ?? "",
            productGroupName: row.productGroupName ?? null,
            productGroupNameEn: row.productGroupNameEn ?? null,
            spec: row.spec ?? null,
            unit: row.unit ?? null,
            note: row.note ?? null,
            quantity: row.replyQuantity ?? 0,
            unitCost: row.replyUnitCost ?? 0,
            detailSubTotal: row.replyDetailSubTotal ?? null,
            replyQuantity: row.replyQuantity ?? null,
            replyUnitCost: row.replyUnitCost ?? null,
            replyDetailSubTotal: row.replyDetailSubTotal ?? null,
            replyDeliveryDate: row.replyDeliveryDate ?? null,
            shippingQuantity: 0,
            confirmFlg: false,
            confirmUserId: null,
            confirmDateTime: null,
            cancelOrderQuantity: null,
            cancelUserId: null,
            cancelDateTime: null,
            r3WbsLv2: null,
            r3OrderNo: null,
            r3ShippingDate: null,
            referenceDetailNo: null,
            isExpenses: false,
            isDeliveryCost: false,
          };
        });
    }
    function inpQuotationToOrder(
      orderDetails: OrderGetResponseSub[]
    ): OrderGetResponse {
      return {
        orderNo: 0,
        quotationNo: quotationNo ?? null,
        statusClassValue: OrderStatus.ORDERED,
        orderClassValue: inpQuotation.quotationClassValue ?? "",
        companyCode: inpQuotation.companyCode,
        companyName: inpQuotation.companyName ?? null,
        imoNo: inpQuotation.imoNo,
        vesselName: inpQuotation.vesselName ?? null,
        vesselBuilder: inpQuotation.vesselBuilder ?? null,
        yardNo: inpQuotation.yardNo ?? null,
        customerOrderNo1: null,
        customerOrderNo2: null,
        deliveryName: inpQuotation.deliveryName,
        deliveryPostalCode: inpQuotation.deliveryPostalCode,
        deliveryAddress: inpQuotation.deliveryAddress,
        deliveryTel: inpQuotation.deliveryTel,
        dispatchFlg: inpQuotation.dispatchFlg,
        dispatchPlace: inpQuotation.dispatchPlace ?? null,
        dispatchDate: inpQuotation.dispatchDate ?? null,
        orderUserId: inpQuotation.quotationUserId ?? 0,
        orderUserName: inpQuotation.quotationUserName ?? "",
        quotationCheckUserId: inpQuotation.checkUserId ?? 0,
        quotationCheckUserName: inpQuotation.checkUserName ?? "",
        orderComment: null,
        orderDateTime: null,
        replyUserId: null,
        replyUserName: null,
        replyComment: null,
        replyDateTime: null,
        replyDeliveryDate: null,
        replyInstallmentDeliveryFlg: inpQuotation.replyInstallmentDeliveryFlg,
        replySubtotalPrice: inpQuotation.replySubtotalPrice ?? null,
        replyDeliveryPrice: inpQuotation.replyDeliveryPrice ?? null,
        replyDiscountPrice: inpQuotation.replyDiscountPrice ?? null,
        replyTax: inpQuotation.replyTax ?? null,
        replyTaxPrice: inpQuotation.replyTaxPrice ?? null,
        replyTotalPrice: inpQuotation.replyTotalPrice ?? null,
        checkUserId: null,
        checkDateTime: null,
        r3Wbs: null,
        r3ShippingDate: null,
        r3Comment: null,
        s3Prefix: inpQuotation.s3Prefix ?? null,
        delFlg: false,
        updateDateTime: null,
        requestReplyDeadline: inpQuotation.requestReplyDeadline ?? null,
        requestDeliveryDate: inpQuotation.requestDeliveryDate ?? null,
        quotationDateTime: inpQuotation.quotationDateTime ?? null,
        shippedComment: null,
        quotationComment: inpQuotation.quotationComment ?? null,
        dockName: inpQuotation.dockName ?? null,
        dockScheduleStart: inpQuotation.dockScheduleStart ?? null,
        dockScheduleEnd: inpQuotation.dockScheduleEnd ?? null,
        rejectUserId: null,
        rejectDateTime: null,
        problemProduct: inpQuotation.problemProduct ?? null,
        problemDetail: inpQuotation.problemDetail ?? null,
        compQuotationFlg: false,
        orderDetail: orderDetails,
        isCompTempSave: false,
        bemacFreeComment: null,
      };
    }

    function confirmMessageId(name: saveButtonName) {
      if (
        inpQuotation.quotationClassValue === OrderFactor.LCM_PARTS &&
        (name === "見積キャンセル" || name === "見積否認")
      ) {
        return "I057";
      } else if (
        inpQuotation.quotationClassValue === OrderFactor.LCM_RECOMMEND &&
        (name === "見積キャンセル" || name === "見積否認")
      ) {
        return "I067";
      } else {
        const messageIdMap: { name: saveButtonName; messageId: string }[] = [
          { name: "見積依頼", messageId: "I009" },
          { name: "見積依頼変更", messageId: "I010" },
          { name: "見積回答", messageId: "I011" },
          { name: "再見積依頼", messageId: "I012" },
          { name: "見積キャンセル", messageId: "I013" },
          { name: "見積注文", messageId: "I014" },
          { name: "見積否認", messageId: "I015" },
        ];
        return messageIdMap.find((it) => it.name === name)?.messageId;
      }
    }

    function savedMessageId(name: saveButtonName) {
      const messageIdMap: { name: saveButtonName; messageId: string }[] = [
        { name: "一時保存", messageId: "I045" },
        { name: "見積依頼", messageId: "I037" },
        { name: "見積依頼変更", messageId: "I038" },
        { name: "見積回答", messageId: "I039" },
        { name: "再見積依頼", messageId: "I040" },
        { name: "見積キャンセル", messageId: "I041" },
        { name: "見積注文", messageId: "I042" },
        { name: "見積否認", messageId: "I043" },
      ];
      return messageIdMap.find((it) => it.name === name)?.messageId;
    }

    // データ保存
    function saveData(buttonName: saveButtonName, orderParam?: OrderParam) {
      // 添付ファイルを処理
      const uploadPromises = Promise.all([
        uploaderCustomerRef.current!.save(),
        uploaderBemacRef.current!.save(),
      ]).then(() => {
        // 添付ファイルの処理が正常終了したら見積登録

        // 見積明細
        // 最終行の新規行を除いてリストを再生成
        let newFormRows = [...inpDetails]
          .filter((it) => !isEmptyRow(it))
          .map((it) => {
            const formRow: FormRowValues = { ...it };
            // BEMACは依頼数量を入力できないので0で補完する
            if (auth.user().bemacFlg && !formRow.quantity) {
              formRow.quantity = 0;
              formRow.detailSubTotal = 0;
            }
            //品目コード=「---」の時は単価、数量を0で補完する
            if (
              formRow.productCode &&
              formRow.productCode === ProductCodeConst.BLANK
            ) {
              formRow.quantity = 0;
              formRow.standardUnitPrice = 0;
              formRow.replyQuantity = 0;
              formRow.replyUnitCost = 0;
            }

            // 見積依頼の場合は回答列に初期値セット
            // LCMの場合はLCMの回答内容のまま
            if (
              !isLcm() &&
              (buttonName === "見積依頼" || buttonName === "見積依頼変更") &&
              formRow.productCode !== ProductCodeConst.EXPENSES
            ) {
              formRow.replyUnitCost = formRow.standardUnitPrice;
              formRow.replyQuantity = formRow.quantity;
              formRow.replyDetailSubTotal =
                (formRow.standardUnitPrice ?? 0) * (formRow.quantity ?? 0);
            }
            return formRow;
          });

        //見積詳細番号を採番しなおす
        for (let i = 0; i < newFormRows.length; i++) {
          newFormRows[i].quotationDetailNo = i + 1;
        }

        const requestSubs: QuotationsPostRequestSub[] = newFormRows.map(
          (it) => {
            // リクエスト明細に変換
            return {
              quotationDetailNo: it.quotationDetailNo,
              productCode: it.productCode,
              productGroupName: it.productGroupName,
              productGroupNameEn: it.productGroupNameEn,
              spec: it.spec,
              unit: it.unit,
              standardUnitPrice: it.standardUnitPrice,
              note: it.note,
              quantity: it.quantity,
              detailSubTotal: it.detailSubTotal,
              replyUnitCost: it.replyUnitCost,
              replyQuantity: it.replyQuantity,
              replyDetailSubTotal: it.replyDetailSubTotal,
              replyDeliveryDate: it.replyDeliveryDate,
              referenceDetailNo: it.referenceDetailNo,
              createUserId: it.createUserId,
              createDateTime: it.createDateTime,
              updateUserId: it.updateUserId,
              updateDateTime: it.updateDateTime,
            };
          }
        );

        const request: QuotationsPostRequest = {
          ...inpQuotation,
          quotationNo: quotationNo,
          statusClassValue: getNextStatus(buttonName),
          lcmKeyCompanyCode: maintenanceScheduleKey
            ? maintenanceScheduleKey.companyCode
            : undefined,
          lcmKeyImoNo: maintenanceScheduleKey
            ? maintenanceScheduleKey.imoNo
            : undefined,
          lcmKeyInspectionYearClassValue: maintenanceScheduleKey
            ? maintenanceScheduleKey.inspectionYearClassValue
            : undefined,
          recommendKeyRecommendId: recommendKey
            ? recommendKey.recommendId
            : undefined,
          quotationsPostRequestSub: requestSubs,
          customerOrderNo1: undefined,
          customerOrderNo2: undefined,
        };

        if (orderParam) {
          request.customerQuotationNo1 = orderParam.customerQuotationNo1;
          request.customerQuotationNo2 = orderParam.customerQuotationNo2;
          request.customerOrderNo1 = orderParam.customerOrderNo1;
          request.customerOrderNo2 = orderParam.customerOrderNo2;
        }

        // 見積登録API実行
        return api
          .post(`/api/v1/quotations`, request)
          .then((response) => {
            const messageId = savedMessageId(buttonName);
            if (messageId) {
              splashMessage.show(messageId);
            }
            afterSaved(buttonName, response.data.quotationNo);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              showDialog({ id: "E027" });
            } else {
              showDialog({ error });
              // 商品マスタの存在チェック
              checkProductCodes();
            }
          });
      });
      return uploadPromises;
    }

    /** 商品マスタの存在チェック */
    async function checkProductCodes() {
      // 注文明細から品目コードを重複なく取得する(空文字等は除く)
      const productCodes = Array.from(
        new Set(
          inpDetails
            .filter(
              (it) =>
                it.productCode.length > 0 &&
                it.productCode !== ProductCodeConst.BLANK
            )
            .map((it) => it.productCode)
        )
      );

      // 商品マスタから商品情報を取得する
      const notExistProductCodes: string[] = [];
      const promises = productCodes.map((productCode) =>
        api
          .get<ProductGetResponse>(`/api/v1/products/${productCode}`)
          .then(() => {})
          .catch((error) => {
            if (error.response.status === 404) {
              notExistProductCodes.push(productCode);
            } else {
              throw error;
            }
          })
      );
      await Promise.all(promises);
      // 注文明細で品目マスタに存在明細の存在フラグを落とす
      setInpDetails((prev) => {
        const rows = prev.map((row) => {
          row.existFlg = !notExistProductCodes.includes(row.productCode ?? "");
          return row;
        });
        return rows;
      });
    }

    function afterSaved(buttonName: saveButtonName, quotationNo: number) {
      setFormChanged(false);

      if (buttonName === "一時保存") {
        setQuotationNo(quotationNo);

        api
          .get<QuotationGetResponse>(`/api/v1/quotations/${quotationNo}`)
          .then((response) => {
            const newQuotation = mapQuotationGetResponseToFormValues(
              response.data
            );
            const detailRows = mapQuotationGetResponseSubToFormRowValues(
              response.data.quotationDetail,
              true
            );
            // stateに保存してモーダルを表示状態にする
            saveInitialDataAndShow(newQuotation, detailRows);
          })
          .catch((error) => showDialog({ error }));
      } else {
        // 一時保存以外は画面を閉じる
        setShow(false);
      }
      if (props.onClickSaveButton) {
        props.onClickSaveButton(buttonName);
      }
    }

    function getNextStatus(name: saveButtonName) {
      switch (name) {
        case "見積依頼":
          return QuotationStatus.REQUESTED;
        case "見積回答":
          return QuotationStatus.ANSWERED;
        case "見積キャンセル":
          return QuotationStatus.DELETED;
        case "見積依頼変更":
          return QuotationStatus.REQUESTED;
        case "再見積依頼":
          return QuotationStatus.DENIED;
        case "見積否認":
          return QuotationStatus.DENIED;
        case "見積注文":
          return QuotationStatus.ACCEPTED;
        default:
          return inpQuotation.statusClassValue;
      }
    }

    // 注文詳細画面保存ボタンクリック
    function handleClickOrderDetailSaveButton(
      buttonName: string,
      retOrderParam?: OrderParam
    ) {
      // 保存処理
      saveData("見積注文", retOrderParam);
    }

    // OrderDetail共通コンポーネントエリアの変更イベント
    function handleChangeHeader(e: OrderDetailChangeEvent) {
      setFormChanged(true);

      // 項目名差異の吸収
      switch (e.name) {
        case "orderUserId":
          const quotationUserId = e.value as number | undefined;
          setInpQuotation((prev) => ({ ...prev, quotationUserId }));
          break;
        case "orderUserName":
          const quotationUserName = e.value as string | undefined;
          setInpQuotation((prev) => ({ ...prev, quotationUserName }));
          break;
        default:
          setInpQuotation((prev) => ({ ...prev, [e.name]: e.value }));
      }

      if (e.name === "dispatchFlg") {
        // 技術者派遣の場合
        if (e.value) {
          // トグルONになったら経費入力のためにスペーサー行を追加(BEMACユーザの見積)
          if (auth.user().bemacFlg && canAddExpensesRow(inpDetails)) {
            setInpDetails((prev) => {
              const rows = [...prev];
              // 最終行（空行のはず）にスペーサーをセット
              rows[rows.length - 1].productCode = ProductCodeConst.BLANK;
              // 空行を追加
              addNewRow(rows, [""]);
              return rows;
            });
            //経費行を追加
            addExpensesRow();
          }
        }
      }
      if (e.name === "replyDiscountPrice" || e.name === "replyTax") {
        // 割引額または税率の場合、合計金額再計算
        calculateReplyPrice(inpDetails);
      }
    }

    // テキストエリアの変更イベント
    function handleChangeTextArea(e: ChangeEvent<HTMLTextAreaElement>) {
      setFormChanged(true);
      setInpQuotation((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    // グリッドのセル値変更イベント
    const onCellValueChanged = (event: CellValueChangedEvent) => {
      setFormChanged(true);
      // 自身が最終行なら一行追加する
      if (event.rowIndex === inpDetails.length - 1 && canAddNewRow()) {
        setInpDetails((prev) => {
          const newRows = prev.concat();
          addNewRow(newRows);
          return newRows;
        });
      }
      // 品目コード変更時は商品単一取得API実行
      if (event.column.getColId() === "productCode") {
        const productCode = encodeURIComponent(event.value + "");
        if (productCode && productCode !== ProductCodeConst.BLANK) {
          const query = `onlyLcmCosts=${
            inpQuotation.quotationClassValue === OrderFactor.LCM_PARTS
              ? "true"
              : "false"
          }`;
          api
            .get<ProductGetResponse>(`/api/v1/products/${productCode}?${query}`)
            .then((response) => {
              setInpDetails((prev) => {
                const newRows = prev.map((it, index) => {
                  if (index === event.rowIndex) {
                    const data = response.data;
                    const newRow = prev[event.rowIndex ?? 0];
                    newRow.productGroupName = data.productGroupName;
                    newRow.productGroupNameEn = data.productGroupNameEn;
                    newRow.spec = data.spec;
                    newRow.standardUnitPrice = data.unitPrice ?? undefined;
                    newRow.unit = data.unit;
                    newRow.note = data.note;
                    newRow.detailSubTotal =
                      (data.unitPrice ?? 0) * (newRow.quantity ?? 0);
                    return newRow;
                  } else {
                    return it;
                  }
                });
                // 金額再計算
                calculateTempPrice(newRows);
                calculateReplyPrice(newRows);

                return newRows;
              });
            })
            .catch((error) => {
              if (error.response.status === 404) {
                showDialog({ id: "E029" });
              } else {
                showDialog({ error });
              }
            });
        } else {
          //品目コードクリア時、または「---」に変更時はレコードの値もクリアする
          setInpDetails((prev) => {
            const newRows = prev.map((it, index) => {
              if (index === event.rowIndex) {
                const newRow: FormRowValues = {
                  productCode: productCode ?? "",
                  key: it.key,
                  isDbRow: it.isDbRow,
                  quotationDetailNo: it.quotationDetailNo,
                  addsByBemac: auth.user().bemacFlg,
                  existFlg: true,
                };
                return newRow;
              } else {
                return it;
              }
            });
            // 金額再計算
            calculateTempPrice(newRows);
            calculateReplyPrice(newRows);
            return newRows;
          });
        }
      }
      // 数量、単価変更時は仮見積金額再計算
      if (
        event.column.getColId() === "quantity" ||
        event.column.getColId() === "standardUnitPrice"
      ) {
        setInpDetails((prev) => {
          const newRows = prev.concat();
          newRows.forEach((row) => {
            row.detailSubTotal =
              (row.standardUnitPrice ?? 0) * (row.quantity ?? 0);
          });
          calculateTempPrice(newRows);
          return newRows;
        });
      }
      // BEMAC回答の数量、単価変更時は金額再計算
      if (
        event.column.getColId() === "replyQuantity" ||
        event.column.getColId() === "replyUnitCost"
      ) {
        setInpDetails((prev) => {
          const newRows = prev.concat();
          newRows.forEach((row) => {
            row.replyDetailSubTotal =
              (row.replyUnitCost ?? 0) * (row.replyQuantity ?? 0);
          });
          // 合計金額再計算
          calculateReplyPrice(newRows);
          return newRows;
        });
      }
    };

    // 合計金額再計算
    function calculateReplyPrice(gridRows: FormRowValues[]) {
      setInpQuotation((prev) => {
        let subtotal = 0;
        gridRows.forEach((row) => {
          subtotal += row.replyDetailSubTotal ?? 0;
        });
        const subtotalMinusDiscount = subtotal - (prev.replyDiscountPrice ?? 0);
        const taxPrice = calculateTax(subtotalMinusDiscount, prev.replyTax);
        const totalPrice = subtotalMinusDiscount + taxPrice;

        return {
          ...prev,
          replySubtotalPrice: subtotal,
          replyTaxPrice: taxPrice,
          replyTotalPrice: totalPrice,
        };
      });
    }

    // 仮見積金額再計算
    function calculateTempPrice(gridRows: FormRowValues[]) {
      let subtotal = 0;
      gridRows.forEach((row) => {
        subtotal += row.detailSubTotal ?? 0;
      });
      setInpQuotation((prev) => ({ ...prev, tempSubtotalPrice: subtotal }));
    }

    function handleClickSearchParts() {
      // 明細の編集を停止
      gridRef.current?.api.stopEditing();
      // 商品一覧を表示
      productListModalRef.current?.show();
    }

    // 行ドラッグ
    let dragRowIndex = -1;
    const onRowDragEnter = useCallback((e: RowDragEnterEvent) => {
      dragRowIndex = e.overIndex;
    }, []);
    const onRowDragEnd = useCallback((e: RowDragEndEvent) => {
      setFormChanged(true);
      // 並び替え後の順番でNoをつけなおす
      const newDatas: FormRowValues[] = [];
      gridRef.current?.api.forEachNode((node, index) => {
        const row = node.data as FormRowValues;
        row.quotationDetailNo = index + 1;
        newDatas.push(row);
      });
      // 最下行を移動させた場合は新規行を追加
      if (
        dragRowIndex !== e.overIndex &&
        dragRowIndex === newDatas.length - 1 &&
        canAddNewRow()
      ) {
        addNewRow(newDatas);
      }

      setInpDetails(newDatas);
    }, []);

    // 行削除
    function onClickDeleteButton(key: number) {
      setFormChanged(true);
      // 自身の行を削除し、その他の行のNoを採番しなおす
      const newRows = inpDetails
        .filter((it) => it.key !== key)
        .map((it, itIndex) => {
          it.quotationDetailNo = itIndex + 1;
          return it;
        });
      setInpDetails(newRows);
      // 金額再計算
      calculateTempPrice(newRows);
      calculateReplyPrice(newRows);
    }

    // 行追加可否
    function canAddNewRow() {
      if (readOnly) {
        // 読み取り専用モードの時は不可
        return false;
      }

      //顧客かつLCM経由の場合は不可
      if (
        !auth.user().bemacFlg &&
        OrderFactor.LCM_PARTS === inpQuotation.quotationClassValue
      ) {
        return false;
      }

      // 「作成中」「依頼中」は可
      return [QuotationStatus.DRAFT, QuotationStatus.REQUESTED].includes(
        inpQuotation.statusClassValue
      );
    }

    // 新規行追加
    function addNewRow(rows: FormRowValues[], productCodes?: string[]) {
      const getMaxKey = (rows: FormRowValues[]) => {
        if (rows.length > 0) {
          return Math.max(...rows.map((it) => it.key));
        }
        return 0;
      };
      // 行を追加(複数も可)
      let maxKey = getMaxKey(rows);
      productCodes?.forEach((productCode) => {
        const newRow: FormRowValues = {
          productCode: productCode,
          key: maxKey + 1,
          isDbRow: false,
          quotationDetailNo: rows.length + 1,
          addsByBemac: auth.user().bemacFlg,
          existFlg: true,
        };
        rows.push(newRow);
        maxKey++;
      });

      // 新規行を追加
      const blankRow: FormRowValues = {
        productCode: "",
        key: maxKey + 1,
        isDbRow: false,
        quotationDetailNo: rows.length + 1,
        addsByBemac: auth.user().bemacFlg,
        existFlg: true,
      };
      rows.push(blankRow);
    }

    //経費行を追加する
    function addExpensesRow() {
      //経費
      const productCode = ProductCodeConst.EXPENSES;

      //商品単一取得API実行
      api
        .get<ProductGetResponse>(`/api/v1/products/${productCode}`)
        .then((response) => {
          setInpDetails((prev) => {
            const newRows = prev.map((it, index) => {
              if (index === prev.length - 2) {
                const data = response.data;
                const newRow = prev[prev.length ? prev.length - 2 : 0];
                newRow.productCode = data.productCode;
                newRow.productGroupName = data.productGroupName;
                newRow.productGroupNameEn = data.productGroupNameEn;
                newRow.spec = data.spec;
                newRow.standardUnitPrice = data.unitPrice ?? undefined;
                newRow.unit = data.unit;
                newRow.note = data.note;
                newRow.detailSubTotal =
                  (data.unitPrice ?? 0) * (newRow.quantity ?? 0);
                newRow.replyQuantity = 1;
                newRow.replyUnitCost = 0;
                return newRow;
              } else {
                return it;
              }
            });
            // 金額再計算
            calculateTempPrice(newRows);
            calculateReplyPrice(newRows);
            return newRows;
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            showDialog({ id: "E029" });
          } else {
            showDialog({ error });
          }
        });
    }

    //準備費行を追加する
    function addReadyRow() {
      //経費
      const productCode = ProductCodeConst.READY;

      //商品単一取得API実行
      api
        .get<ProductGetResponse>(`/api/v1/products/${productCode}`)
        .then((response) => {
          setInpDetails((prev) => {
            const newRows = prev.map((it, index) => {
              if (index === prev.length - 3) {
                const data = response.data;
                const newRow = prev[prev.length ? prev.length - 3 : 0];
                newRow.productCode = data.productCode;
                newRow.productGroupName = data.productGroupName;
                newRow.productGroupNameEn = data.productGroupNameEn;
                newRow.spec = data.spec;
                newRow.standardUnitPrice = data.unitPrice ?? undefined;
                newRow.unit = data.unit;
                newRow.note = data.note;
                newRow.detailSubTotal =
                  (data.unitPrice ?? 0) * (newRow.quantity ?? 0);
                newRow.replyQuantity = 1;
                newRow.replyUnitCost = 0;
                return newRow;
              } else {
                return it;
              }
            });
            // 金額再計算
            calculateTempPrice(newRows);
            calculateReplyPrice(newRows);
            return newRows;
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            showDialog({ id: "E029" });
          } else {
            showDialog({ error });
          }
        });
    }
    //経費行追加可否判定
    function canAddExpensesRow(rows: FormRowValues[]) {
      return (
        rows.filter((it) => it.productCode === ProductCodeConst.EXPENSES)
          .length === 0
      );
    }

    //準備費追加可否判定
    function canAddReadyRow(
      rows: FormRowValues[],
      quotationClassValue?: string
    ) {
      return (
        rows.filter(
          (it) =>
            it.productCode === ProductCodeConst.READY &&
            quotationClassValue &&
            quotationClassValue === OrderFactor.LCM_PARTS
        ).length === 0
      );
    }

    // BEMAC回答列表示条件
    function showsAnswerColumns() {
      if (
        !auth.user().bemacFlg &&
        (inpQuotation.statusClassValue === QuotationStatus.DRAFT ||
          inpQuotation.statusClassValue === QuotationStatus.REQUESTED)
      ) {
        return false;
      }
      return true;
    }

    function isLcm() {
      return inpQuotation.quotationClassValue === OrderFactor.LCM_PARTS;
    }

    // 技師一般ユーザかどうか
    function isGeneralEngineer() {
      return auth.isEngineer() && !auth.user().adminFlg;
    }

    function isEmptyRow(row: FormRowValues) {
      // 入力項目がすべて空の場合は空行とみなす
      if (row.productCode) {
        return false;
      }
      if (row.spec) {
        return false;
      }
      if (row.quantity) {
        return false;
      }
      if (row.replyUnitCost) {
        return false;
      }
      if (row.replyQuantity) {
        return false;
      }

      return true;
    }
    // 添付ファイル使用可否判定
    function editableFileUpload(isBemacArea: boolean) {
      if (readOnly) {
        // 読み取り専用モードの時は編集不可
        return false;
      }
      if (auth.user().bemacFlg !== isBemacArea) {
        // BEMAC・顧客それぞれ自身のエリアのみ編集可能
        return false;
      }
      // ステータスが「確認中」の時は編集不可
      if (inpQuotation.statusClassValue === QuotationStatus.ANSWERED) {
        return false;
      }

      // その他の場合編集可能
      return true;
    }

    // コメント編集可否判定
    function editableComment(isBemacArea: boolean) {
      if (readOnly) {
        // 読み取り専用モードの時は編集不可
        return false;
      }
      if (auth.user().bemacFlg !== isBemacArea) {
        // BEMAC・顧客それぞれ自身のエリアのみ編集可能
        return false;
      }

      if (auth.user().bemacFlg) {
        // BEMAC：作成中、依頼中は編集可能
        switch (inpQuotation.statusClassValue) {
          case QuotationStatus.DRAFT:
          case QuotationStatus.REQUESTED:
            return true;
        }
      } else {
        // 顧客：作成中、依頼中、回答確認中は編集可能
        switch (inpQuotation.statusClassValue) {
          case QuotationStatus.DRAFT:
          case QuotationStatus.REQUESTED:
          case QuotationStatus.ANSWERED:
            return true;
        }
      }

      // その他の場合編集不可
      return false;
    }

    // 商品一覧で部品を選択
    function handleSelectProducts(products: ProductsGetResponse[]) {
      setFormChanged(true);
      // 最終行の新規行を除いてリストを再生成
      const newInputDatas = [...inpDetails].filter(
        (it) => it.quotationDetailNo !== inpDetails.length || !isEmptyRow(it)
      );
      // 選択された部品を追加
      let nextKey = Math.max(...inpDetails.map((it) => it.key)) + 1;
      let nextNo = newInputDatas.length + 1;
      const addDatas: FormRowValues[] = products.map((it) => {
        return {
          key: nextKey++,
          isDbRow: false,
          quotationDetailNo: nextNo++,
          productCode: it.productCode,
          productGroupName: it.productGroupName,
          productGroupNameEn: it.productGroupNameEn,
          spec: it.spec,
          standardUnitPrice: it.unitPrice,
          quantity: auth.user().bemacFlg ? undefined : 1,
          unit: it.unit,
          note: it.note,
          detailSubTotal: it.unitPrice,
          replyUnitCost: auth.user().bemacFlg ? it.unitPrice : undefined,
          replyQuantity: auth.user().bemacFlg ? 1 : undefined,
          replyDetailSubTotal: auth.user().bemacFlg ? it.unitPrice : undefined,
          addsByBemac: auth.user().bemacFlg,
          existFlg: true,
        };
      });
      // 新規行を追加
      const emptyRow: FormRowValues = {
        productCode: "",
        key: nextKey,
        isDbRow: false,
        quotationDetailNo: nextNo,
        addsByBemac: auth.user().bemacFlg,
        existFlg: true,
      };
      // stateに保存
      const newRows = [...newInputDatas, ...addDatas, emptyRow];
      setInpDetails(newRows);
      // 金額再計算
      calculateTempPrice(newRows);
      calculateReplyPrice(newRows);
    }

    // 見積書PDFボタンクリック
    function handleClickOutputPdf() {
      // 保存してなかったらエラー
      if (!quotationNo || formChanged) {
        showDialog({
          id: "E084",
          args: [tc("見積")],
        });
        return;
      }

      api
        .get(`/api/v1/quotation-pdf/${quotationNo}`, {
          responseType: "blob",
        })
        .then((it) => {
          const pdf = new Blob([it.data], { type: "application/pdf" });
          const pdfURL = URL.createObjectURL(pdf);
          window.open(pdfURL);
        })
        .catch((error) => {
          showDialog({ error });
        });
    }

    // 行ドラッグ可否判定
    function canDragRow() {
      if (readOnly) {
        // 読み取り専用モードの時は編集不可
        return false;
      }
      switch (inpQuotation.statusClassValue) {
        case QuotationStatus.DRAFT:
        case QuotationStatus.REQUESTED:
          // 顧客はECのみ可
          return auth.user().bemacFlg || !isLcm();
        default:
          return false;
      }
    }

    // 仮見積金額エリア表示判定
    function showsTempPrice() {
      // 顧客ユーザかつ未回答か再見積
      return (
        !auth.user().bemacFlg &&
        (!inpQuotation.replyDateTime || inpQuotation.reQuotationFlg)
      );
    }

    // セルの文字を赤くする判定
    function showsChangeColor(row: ICellRendererParams<FormRowValues>) {
      if (inpQuotation.statusClassValue !== QuotationStatus.ANSWERED) {
        // 回答確認中以外は適用しない
        return false;
      }
      if (inpQuotation.bemacStartFlg) {
        // BEMAC起点は適用しない
        return false;
      }

      const data = row.data!;
      const isBemacRow = data.quantity === 0;
      const hasChangedCost = data.replyUnitCost !== data.standardUnitPrice;
      const hasChangedQuantity = data.replyQuantity !== data.quantity;
      const hasChangedSubTotal =
        data.replyDetailSubTotal !== data.detailSubTotal;

      // 依頼と回答で値が異なるものを赤文字にする
      switch (row.colDef?.field) {
        case "replyUnitCost":
          return isBemacRow || hasChangedCost;
        case "replyQuantity":
          return isBemacRow || hasChangedQuantity;
        case "replyDetailSubTotal":
          return isBemacRow || hasChangedSubTotal;
        default:
          return isBemacRow;
      }
    }

    // 列編集可否判定
    function editableColumn(row: ICellRendererParams<FormRowValues>) {
      if (readOnly) {
        // 読み取り専用モードの時は編集不可
        return false;
      }

      const data = row.data!;

      // 品目コード入力済みの場合は単位入力不可
      if (data.productCode && row.colDef?.field === "unit") {
        return false;
      }

      // 依頼数量はBEMACまたは品目コード=「---」の場合は入力不可
      if (row.colDef?.field === "quantity") {
        if (
          auth.user().bemacFlg ||
          data?.productCode === ProductCodeConst.BLANK
        ) {
          return false;
        }
      }

      // LCM、レコメンドから渡された明細は編集不可(経費明細は除く)
      // 顧客で依頼数量セルの場合は編集可
      if (data.referenceDetailNo) {
        if (
          (auth.user().bemacFlg &&
            data.productCode &&
            (data.productCode !== ProductCodeConst.SPECIAL_CODES ||
              (data.productCode === ProductCodeConst.SPECIAL_CODES &&
                row.colDef?.field !== "spec"))) ||
          (!auth.user().bemacFlg && row.colDef?.field !== "quantity")
        ) {
          return false;
        }
      }

      // LCMかつ顧客ユーザの場合は明細の編集不可
      if (isLcm() && !auth.user().bemacFlg) {
        return false;
      }

      switch (inpQuotation.statusClassValue) {
        case QuotationStatus.DRAFT:
        case QuotationStatus.REQUESTED:
          // 「作成中」「依頼中」は自社が追加した行のみ編集可
          return (
            (auth.user().bemacFlg && data.addsByBemac) ||
            (!auth.user().bemacFlg && !data.addsByBemac)
          );
        default:
          // その他は編集不可
          return false;
      }
    }

    // BEMAC回答列の編集可否判定
    function editableReplyColumn(rowData: ICellRendererParams<FormRowValues>) {
      if (readOnly) {
        // 読み取り専用モードの時は編集不可
        return false;
      }

      // LCMから渡された明細で臨注品以外は編集不可
      if (
        isLcm() &&
        rowData.data?.referenceDetailNo &&
        rowData.data?.productCode !== ProductCodeConst.SPECIAL_CODES
      ) {
        return false;
      }

      //経費行、準備費行、臨注品の場合は回答数量のみ編集不可
      if (
        (rowData.data?.productCode === ProductCodeConst.EXPENSES ||
          rowData.data?.productCode === ProductCodeConst.READY ||
          (rowData.data?.productCode === ProductCodeConst.SPECIAL_CODES &&
            rowData.data?.referenceDetailNo)) &&
        rowData.colDef?.field === "replyQuantity"
      ) {
        return false;
      }

      //品目コード=「---」の場合は編集不可
      if (rowData.data?.productCode === ProductCodeConst.BLANK) {
        return false;
      }

      // 自身がBEMACユーザで「作成中」「依頼中」は編集可
      switch (inpQuotation.statusClassValue) {
        case QuotationStatus.DRAFT:
        case QuotationStatus.REQUESTED:
          return auth.user().bemacFlg;
        default:
          return false;
      }
    }

    // グリッド内の削除ボタン使用可否
    function canDeleteRow(rowData: ICellRendererParams<FormRowValues>) {
      // 最終行または技師派遣フラグON時の経費行、準備費行の場合は削除不可
      if (
        rowData.data?.quotationDetailNo === inpDetails.length ||
        ((rowData.data?.productCode === ProductCodeConst.EXPENSES ||
          rowData.data?.productCode === ProductCodeConst.READY) &&
          inpQuotation.dispatchFlg)
      ) {
        return false;
      }
      return editableColumn(rowData);
    }

    // 削除ボタンレンダリング
    function rendererDeleteButton(rowData: ICellRendererParams<FormRowValues>) {
      if (canDeleteRow(rowData)) {
        return (
          <span>
            <a
              style={{ color: "black" }}
              href="#"
              onClick={(e) => onClickDeleteButton(rowData.data?.key ?? -1)}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HiOutlineTrash size={20} style={{ margin: "10 10px" }} />
              </div>
            </a>
          </span>
        );
      } else {
        return <></>;
      }
    }
    // グリッドの列定義
    const columnDefs = [
      {
        headerName: tc("明細"),
        children: [
          {
            headerName: "No",
            field: "quotationDetailNo",
            width: 90,
            pinned: true,
            rowDrag: canDragRow(),
            cellClassRules: {
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
          {
            headerName: tc("品目コード"),
            field: "productCode",
            width: 120,
            pinned: true,
            editable: editableColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableColumn(params) && params.data?.existFlg,
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
        ],
      },
      {
        headerName: "",
        children: [
          {
            headerName: tc("品名"),
            field: "productGroupName",
            cellClassRules: {
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            valueFormatter: agProductNameFormatter,
          },
          {
            headerName: tc("型式"),
            field: "spec",
            editable: editableColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableColumn(params) && params.data?.existFlg,
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
          {
            headerName: tc("単価"),
            width: 100,
            field: "standardUnitPrice",
            cellClass: "b-grid-cell-multi-line justify-content-end",
            valueFormatter: (e: ValueFormatterParams) => {
              if (
                isEmptyRow(e.data) ||
                e.data.productCode === ProductCodeConst.BLANK
              ) {
                return "";
              }
              return agNumberFormatter(e, tc("要問合せ"));
            },
            cellClassRules: {
              "text-start": (params: any) =>
                params.data?.standardUnitPrice === null ||
                typeof params.data?.standardUnitPrice === "undefined",
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
          {
            headerName: tc("依頼数量"),
            width: isEnglish() ? 110 : 100,
            field: "quantity",
            cellStyle: { textAlign: "right" },
            editable: editableColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableColumn(params) && params.data?.existFlg,
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            valueFormatter: (e: ValueFormatterParams) => {
              if (
                isEmptyRow(e.data) ||
                e.data.productCode === ProductCodeConst.BLANK
              ) {
                return "";
              }
              return agNumberFormatter(e);
            },
            valueSetter: (params: ValueSetterParams) =>
              agNumberSetter(params, "quantity"),
          },
          {
            headerName: tc("単位"),
            width: 100,
            field: "unit",
            editable: editableColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableColumn(params) && params.data?.existFlg,
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
          {
            headerName: tc("小計"),
            width: 100,
            field: "detailSubTotal",
            cellStyle: { textAlign: "right" },
            cellClassRules: {
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            valueFormatter: (e: ValueFormatterParams) => {
              if (
                isEmptyRow(e.data) ||
                e.data.productCode === ProductCodeConst.BLANK
              ) {
                return "";
              }
              return agNumberFormatter(e);
            },
          },
          {
            headerName: tc("明細備考"),
            field: "note",
            cellClassRules: {
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
          {
            headerName: tc("削除"),
            colId: "deleteByUser",
            width: isEnglish() ? 90 : 80,
            cellRenderer: rendererDeleteButton,
            hide: auth.user().bemacFlg || readOnly,
            cellClassRules: {
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
        ],
      },
      {
        headerName: tc("BEMAC回答"),
        headerClass: "b-ag-column-reply",
        children: [
          {
            headerName: tc("単価"),
            width: 100,
            field: "replyUnitCost",
            cellStyle: { textAlign: "right" },
            valueFormatter: (e: ValueFormatterParams) => {
              if (
                isEmptyRow(e.data) ||
                e.data.productCode === ProductCodeConst.BLANK
              ) {
                return "";
              }
              return agNumberFormatter(e);
            },
            editable: editableReplyColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableReplyColumn(params) && params.data?.existFlg,
              "b-ag-column-reply": (params: any) =>
                !editableReplyColumn(params),
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            hide: !showsAnswerColumns() || isGeneralEngineer(),
            headerClass: "b-ag-column-reply",
            valueSetter: (params: ValueSetterParams) =>
              agNumberSetter(params, "replyUnitCost"),
          },
          {
            headerName: tc("数量"),
            width: 100,
            field: "replyQuantity",
            cellStyle: { textAlign: "right" },
            editable: editableReplyColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableReplyColumn(params) && params.data?.existFlg,
              "b-ag-column-reply": (params: any) =>
                !editableReplyColumn(params),
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            hide: !showsAnswerColumns(),
            headerClass: "b-ag-column-reply",
            valueFormatter: (e: ValueFormatterParams) => {
              if (
                isEmptyRow(e.data) ||
                e.data.productCode === ProductCodeConst.BLANK
              ) {
                return "";
              }
              return agNumberFormatter(e);
            },
            valueSetter: (params: ValueSetterParams) =>
              agNumberSetter(params, "replyQuantity"),
          },
          {
            headerName: tc("小計"),
            width: 100,
            field: "replyDetailSubTotal",
            cellClass: "b-ag-column-reply",
            cellStyle: { textAlign: "right" },
            cellClassRules: {
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            valueFormatter: (e: ValueFormatterParams) => {
              if (
                isEmptyRow(e.data) ||
                e.data.productCode === ProductCodeConst.BLANK
              ) {
                return "";
              }
              return agNumberFormatter(e);
            },
            hide: !showsAnswerColumns() || isGeneralEngineer(),
            headerClass: "b-ag-column-reply",
          },
          {
            headerName: tc("納期"),
            field: "replyDeliveryDate",
            width: 135,
            cellEditor: AgDatePicker,
            editable: editableReplyColumn,
            cellClassRules: {
              "b-editable-cell": (params: any) =>
                editableReplyColumn(params) && params.data?.existFlg,
              "b-ag-column-reply": (params: any) =>
                !editableReplyColumn(params),
              "b-changed-cell": showsChangeColor,
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
            hide:
              !showsAnswerColumns() ||
              !inpQuotation.replyInstallmentDeliveryFlg,
            headerClass: "b-ag-column-reply",
          },
          {
            headerName: tc("削除"),
            colId: "deleteByBemac",
            width: isEnglish() ? 90 : 80,
            cellRenderer: rendererDeleteButton,
            hide: !auth.user().bemacFlg || readOnly,
            headerClass: "b-ag-column-reply",
            cellClassRules: {
              "b-error-cell": (params: any) => !params.data?.existFlg,
            },
          },
        ],
      },
    ];

    // お客様備考注釈表示条件(作成中)
    function showsUserCommentDraft() {
      if (auth.user().bemacFlg) {
        // BEMACは非表示
        return false;
      }

      // 作成中の場合表示
      if (inpQuotation.statusClassValue === QuotationStatus.DRAFT) {
        return true;
      }
      // その他は非表示
      return false;
    }

    // お客様備考注釈表示条件(キャンセル・否認)
    function showsUserCommentDenied() {
      if (auth.user().bemacFlg) {
        // BEMACは非表示
        return false;
      }

      // 依頼中、回答確認中の場合表示
      if (
        inpQuotation.statusClassValue === QuotationStatus.REQUESTED ||
        inpQuotation.statusClassValue === QuotationStatus.ANSWERED
      ) {
        return true;
      }
      // その他は非表示
      return false;
    }

    // モーダルbody部レンダリング
    function modalBody() {
      return (
        <>
          <OrderDetailHeader
            readOnly={readOnly}
            type={OrderDetailType.Quotation}
            status={inpQuotation.statusClassValue}
            orderFactor={inpQuotation.quotationClassValue ?? ""}
            quotationNo={quotationNo}
            quotationDateTime={inpQuotation.quotationDateTime ?? ""}
            replyUserId={inpQuotation.replyUserId ?? undefined}
            replyUserName={inpQuotation.replyUserName ?? ""}
            quotationCheckUserId={inpQuotation.checkUserId}
            quotationCheckUserName={inpQuotation.checkUserName}
            companyCode={inpQuotation.companyCode}
            companyName={inpQuotation.companyName ?? ""}
            orderUserId={inpQuotation.quotationUserId}
            orderUserName={inpQuotation.quotationUserName}
            vesselName={inpQuotation.vesselName ?? ""}
            imoNo={inpQuotation.imoNo ?? ""}
            vesselBuilder={inpQuotation.vesselBuilder ?? ""}
            yardNo={inpQuotation.yardNo ?? ""}
            customerQuotationNo1={inpQuotation.customerQuotationNo1 ?? ""}
            customerQuotationNo2={inpQuotation.customerQuotationNo2 ?? ""}
            requestDeliveryDate={inpQuotation.requestDeliveryDate ?? ""}
            requestReplyDeadline={inpQuotation.requestReplyDeadline ?? ""}
            dispatchFlg={inpQuotation.dispatchFlg}
            dispatchPlace={inpQuotation.dispatchPlace ?? ""}
            dispatchDate={inpQuotation.dispatchDate ?? ""}
            replyInstallmentDeliveryFlg={
              inpQuotation.replyInstallmentDeliveryFlg
            }
            dockName={inpQuotation.dockName ?? ""}
            dockScheduleStart={inpQuotation.dockScheduleStart ?? ""}
            dockScheduleEnd={inpQuotation.dockScheduleEnd ?? ""}
            problemProduct={inpQuotation.problemProduct ?? ""}
            problemDetail={inpQuotation.problemDetail ?? ""}
            onChange={handleChangeHeader}
            reQuotationFlg={inpQuotation.reQuotationFlg}
            compQuotationFlg={false}
            isCompTempSave={false}
            quotationOrderHistoryFlg={props.quotationOrderHistoryFlg}
          />
          <div>
            <div
              className="ag-theme-alpine w-100 mx-auto b-detail-items b-grid-outer b-header-row-white"
              data-cy="明細グリッド"
            >
              {showsUserCommentDraft() && (
                <span style={{ color: "red", fontSize: "16px" }}>
                  {tc(
                    "※品目等が分からない場合は、お客様備考へ記載してください。"
                  )}
                </span>
              )}
              <AgGridReact
                domLayout="autoHeight"
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowData={inpDetails}
                onCellValueChanged={onCellValueChanged}
                singleClickEdit
                ref={gridRef}
                rowDragManaged={true}
                onRowDragEnter={onRowDragEnter}
                onRowDragEnd={onRowDragEnd}
                stopEditingWhenCellsLoseFocus={true}
              />
              <div className="row">
                <div className="col-4">
                  {auth.user().bemacFlg && (
                    <MdOutlineContentCopy
                      size="30px"
                      onClick={() => copyToClipboard()}
                      cursor="pointer"
                      style={{ marginTop: "30px" }}
                    />
                  )}
                </div>
                <div className="col-4">
                  <div className="text-center">
                    {canAddNewRow() && (
                      <input
                        type="button"
                        className="btn b-btn-primary"
                        value={tc("部品検索")}
                        onClick={handleClickSearchParts}
                        data-cy="部品検索ボタン"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-between mt-2">
              {/* 金額 */}
              <div className="col-4">
                {!isGeneralEngineer() && (
                  <OrderDetailPrice
                    readOnly={readOnly}
                    type={OrderDetailType.Quotation}
                    status={inpQuotation.statusClassValue}
                    replySubtotalPrice={inpQuotation.replySubtotalPrice}
                    replyDiscountPrice={inpQuotation.replyDiscountPrice}
                    replyTax={inpQuotation.replyTax}
                    replyTaxPrice={inpQuotation.replyTaxPrice}
                    replyTotalPrice={inpQuotation.replyTotalPrice}
                    onChange={handleChangeHeader}
                    reQuotationFlg={inpQuotation.reQuotationFlg}
                    showTempPrice={showsTempPrice()}
                    tempSubtotalPrice={inpQuotation.tempSubtotalPrice}
                  />
                )}
              </div>
              {/* 送付先 */}
              <div className="col-4">
                <OrderDetailDelivery
                  readOnly={readOnly}
                  type={OrderDetailType.Quotation}
                  status={inpQuotation.statusClassValue}
                  deliveryName={inpQuotation.deliveryName}
                  deliveryPostalCode={inpQuotation.deliveryPostalCode}
                  deliveryAddress={inpQuotation.deliveryAddress}
                  deliveryTel={inpQuotation.deliveryTel}
                  bemacStartFlg={inpQuotation.bemacStartFlg}
                  reQuotationFlg={inpQuotation.reQuotationFlg}
                  onChange={handleChangeHeader}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className={`${areaClass} pt-2`}>
                  <div className="pb-1">
                    <span
                      className="b-input-group-text"
                      style={{ display: "inline" }}
                    >
                      {tc("お客様 添付ファイル")}
                    </span>
                    {editableFileUpload(false) && (
                      <span className="ms-2" style={{ color: "red" }}>
                        {tc("※ファイル添付は、最大10件までです。")}
                      </span>
                    )}
                  </div>
                  <S3Uploader
                    editable={editableFileUpload(false)}
                    maxCount={10}
                    ref={uploaderCustomerRef}
                  />
                </div>
              </div>
              {/* BEMAC：見積時の添付ファイル */}
              <div className="col-6">
                <div className={`${areaClass} pt-2`}>
                  <div className="pb-1">
                    <span
                      className="b-input-group-text-light_blue"
                      style={{ display: "inline" }}
                    >
                      {tc("BEMAC 添付ファイル")}
                    </span>
                    {editableFileUpload(true) && (
                      <span className="ms-2" style={{ color: "red" }}>
                        {tc("※ファイル添付は、最大10件までです。")}
                      </span>
                    )}
                  </div>
                  <S3Uploader
                    editable={editableFileUpload(true)}
                    maxCount={10}
                    ref={uploaderBemacRef}
                  />
                </div>
              </div>
            </div>
            {/* 備考、コメント */}
            <div className="row">
              <div className="col-6">
                <div className={areaClass}>
                  {showsUserCommentDraft() && (
                    <span className="mt-2" style={{ color: "red" }}>
                      {tc(
                        "※品目等が分からない場合は、こちらへご記入ください。（過去の見積書を添付していただいても対応致します。）"
                      )}
                    </span>
                  )}
                  {showsUserCommentDenied() && (
                    <span className="mt-2" style={{ color: "red" }}>
                      {tc(
                        "※キャンセルや否認をされる際は、理由を備考にご記入ください。"
                      )}
                    </span>
                  )}
                  <div
                    className="b-input-group-text"
                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                  >
                    {tc("お客様備考")}
                  </div>
                  <textarea
                    name="quotationComment"
                    className={textAreaClass}
                    disabled={!editableComment(false)}
                    value={inpQuotation.quotationComment}
                    maxLength={500}
                    onChange={handleChangeTextArea}
                    data-cy="お客様備考テキスト"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className={areaClass}>
                  <div
                    className="b-input-group-text-light_blue"
                    style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                  >
                    {tc("BEMACコメント")}
                  </div>
                  <textarea
                    name="replyComment"
                    className={textAreaClass}
                    disabled={!editableComment(true)}
                    value={inpQuotation.replyComment}
                    maxLength={500}
                    onChange={handleChangeTextArea}
                    data-cy="BEMACコメントテキスト"
                  />
                </div>
              </div>
            </div>
          </div>
          <ProductListModal
            onDecide={handleSelectProducts}
            onlyLcmCosts={
              inpQuotation.quotationClassValue === OrderFactor.LCM_PARTS
            }
            ref={productListModalRef}
          />
          <OrderDetail
            ref={orderDetailRef}
            onClickSaveButton={handleClickOrderDetailSaveButton}
            fromQuotationDetail={true}
          />
        </>
      );
    }

    // モーダルfooter部レンダリング
    function modalFooter() {
      return (
        <>
          {
            // 下書き or 新規作成
            inpQuotation.statusClassValue === QuotationStatus.DRAFT && (
              <>
                {auth.user().bemacFlg && (
                  <>
                    {!readOnly && (
                      <>
                        <Button
                          className="b-btn-light"
                          onClick={(e) => handleClickActionButton("一時保存")}
                          data-cy="一時保存ボタン"
                        >
                          {tc("一時保存")}
                        </Button>
                        {!auth.isEngineer() && (
                          <Button
                            className="b-btn-light"
                            onClick={(e) => handleClickOutputPdf()}
                            data-cy="見積書PDFボタン"
                          >
                            {tc("見積書PDF")}
                          </Button>
                        )}
                        <Button
                          className="b-btn-primary m-0"
                          onClick={(e) => handleClickActionButton("見積回答")}
                          data-cy="見積回答ボタン"
                        >
                          {tc("見積回答")}
                        </Button>
                      </>
                    )}
                  </>
                )}
                {!auth.user().bemacFlg && (
                  <>
                    {!readOnly && (
                      <>
                        <Button
                          className="b-btn-light"
                          onClick={(e) => handleClickActionButton("一時保存")}
                          data-cy="一時保存ボタン"
                        >
                          {tc("一時保存")}
                        </Button>
                        <Button
                          className="b-btn-primary m-0"
                          onClick={(e) => handleClickActionButton("見積依頼")}
                          data-cy="見積依頼ボタン"
                        >
                          {tc("見積依頼")}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )
          }
          {
            // 見積依頼中
            inpQuotation.statusClassValue === QuotationStatus.REQUESTED && (
              <>
                {auth.user().bemacFlg && (
                  <>
                    {!readOnly && (
                      <Button
                        className="b-btn-light"
                        onClick={(e) => handleClickActionButton("一時保存")}
                        data-cy="一時保存ボタン"
                      >
                        {tc("一時保存")}
                      </Button>
                    )}
                    {!auth.isEngineer() && (
                      <Button
                        className="b-btn-light"
                        onClick={(e) => handleClickOutputPdf()}
                        data-cy="見積書PDFボタン"
                      >
                        {tc("見積書PDF")}
                      </Button>
                    )}
                    {!readOnly && (
                      <Button
                        className="b-btn-primary m-0"
                        onClick={(e) => handleClickActionButton("見積回答")}
                        data-cy="見積回答ボタン"
                      >
                        {tc("見積回答")}
                      </Button>
                    )}
                  </>
                )}
                {!auth.user().bemacFlg && (
                  <>
                    {!readOnly && (
                      <>
                        <Button
                          className="b-btn-light"
                          onClick={(e) =>
                            handleClickActionButton("見積キャンセル")
                          }
                          data-cy="見積キャンセルボタン"
                        >
                          {tc("見積キャンセル")}
                        </Button>
                        <Button
                          className="b-btn-primary m-0"
                          onClick={(e) =>
                            handleClickActionButton("見積依頼変更")
                          }
                          data-cy="見積依頼変更ボタン"
                        >
                          {tc("見積依頼変更")}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )
          }
          {
            // 見積回答確認中
            inpQuotation.statusClassValue === QuotationStatus.ANSWERED && (
              <>
                {auth.user().bemacFlg && (
                  <>
                    {!auth.isEngineer() && (
                      <Button
                        className="b-btn-light"
                        onClick={(e) => handleClickOutputPdf()}
                        data-cy="見積書PDFボタン"
                      >
                        {tc("見積書PDF")}
                      </Button>
                    )}
                  </>
                )}
                {!auth.user().bemacFlg && (
                  <>
                    <Button
                      className="b-btn-light"
                      onClick={(e) => handleClickOutputPdf()}
                      data-cy="見積書PDFボタン"
                    >
                      {tc("見積書PDF")}
                    </Button>
                    {!readOnly && (
                      <>
                        <Button
                          className="b-btn-light"
                          onClick={(e) => handleClickActionButton("再見積依頼")}
                          data-cy="再見積依頼ボタン"
                        >
                          {tc("再見積依頼")}
                        </Button>
                        <Button
                          className="b-btn-light"
                          onClick={(e) => handleClickActionButton("見積否認")}
                          data-cy="見積否認ボタン"
                        >
                          {tc("見積否認")}
                        </Button>
                        <Button
                          className="b-btn-primary m-0"
                          onClick={(e) => handleClickActionButton("見積注文")}
                          data-cy="見積注文ボタン"
                        >
                          {tc("見積注文")}
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )
          }
          {
            // 見積承認後
            inpQuotation.statusClassValue === QuotationStatus.ACCEPTED && (
              <>
                {!auth.isEngineer() && (
                  <Button
                    className="b-btn-light"
                    onClick={(e) => handleClickOutputPdf()}
                    data-cy="見積書PDFボタン"
                  >
                    {tc("見積書PDF")}
                  </Button>
                )}
              </>
            )
          }
          <Button
            className="b-btn-close"
            onClick={handleClose}
            data-cy="Closeボタン"
          >
            Close
          </Button>
        </>
      );
    }

    // レンダリング
    return (
      <Modal
        fullscreen={true}
        show={show}
        onShow={handleShow}
        onHide={handleClose}
        scrollable
        data-cy="見積詳細モーダル"
      >
        <Modal.Header closeButton>
          <Modal.Title>{tc("見積詳細")}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="b-modal-detail"
          style={{ padding: "0px 12px 0px 12px" }}
        >
          {modalBody()}
        </Modal.Body>
        <Modal.Footer className="b-modal-detail">{modalFooter()}</Modal.Footer>
      </Modal>
    );
  }
);

export default QuotationDetail;
