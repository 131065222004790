import dayjs from "dayjs";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useDialog } from "src/context/DialogProvider";
import { useDispatchReport } from "src/hook/useDispatchReport";
import { VesselTroublesGetResponse } from "src/model/api/response/VesselTroublesGetResponse";
import { VesselVisitsGetResponse } from "src/model/api/response/VesselVisitsGetResponse";
import { formatDate } from "src/util/ecUtil";

type Props = {
  /** IMO */
  imoNo: string | undefined;
  /** 訪船履歴 */
  visits: VesselVisitsGetResponse[];
  /** 不具合対応履歴 */
  troubles: VesselTroublesGetResponse[];
  /** 対象年 */
  year: number | undefined;
};

function MaintenanceHistoryListDataArea(props: Props) {
  const cellStyle: CSSProperties = {
    border: "solid",
    borderWidth: "1px",
    borderColor: "#ced4da",
    height: "100%",
    minHeight: "100px",
    padding: "5px",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: "5px",
    position: "relative",
  };
  const rowStyle: CSSProperties = {
    display: "flex",
    width: "100%",
  };
  const titleStyle: CSSProperties = {
    flexBasis: "100px",
    whiteSpace: "nowrap",
  };
  const titleStyleVisit: CSSProperties = {
    ...titleStyle,
    flexBasis: "60px",
  };
  const bodyStyle: CSSProperties = {
    flexGrow: "1",
    whiteSpace: "pre-wrap",
  };

  // ダイアログ使用宣言
  const showDialog = useDialog();
  // 翻訳
  const { t: tc } = useTranslation("MaintenanceHistoryListDataArea");
  // 工事レポート
  const dispatchReport = useDispatchReport();

  /** プロパティの訪船履歴から選択年に一致するものを抜き出す */
  function getThisYearVisits() {
    // 出港日の年が一致するもの
    return props.visits.filter(
      (visit) =>
        visit.estimatedTimeDeparture &&
        dayjs(visit.estimatedTimeDeparture).year() === props.year
    );
  }

  /** プロパティの不具合対応履歴から選択年に一致するものを抜き出す */
  function getThisTroubles() {
    // 不具合一報日の年が一致するもの
    return props.troubles.filter(
      (trouble) =>
        trouble.troubleNoticeDate &&
        dayjs(trouble.troubleNoticeDate).year() === props.year
    );
  }

  /** more...のリンククリック */
  function handleClickMoreLink(text: string | null) {
    showDialog({
      text: text ?? "",
      fullScreenFlg: true,
    });
  }

  /** サービスレポートボタンクリック */
  function handleClickServiceReportButton(visit: VesselVisitsGetResponse) {
    const imono = visit.imoNo;
    const wbses = visit.wbses
      .flatMap((it) => it.wbs) // 配列の配列を1階層に変換
      .map((it) =>
        it && it.length >= 7
          ? it.substring(0, 3) + "-" + it.substring(3, 7)
          : ""
      ) // string|null を stringにするための変換
      .filter((it) => it); // 空文字を除去

    //wbesが空だったらシンガポール支社対応分か判定
    if (
      wbses.length === 0 &&
      visit.colorClass &&
      visit.estimatedTimeArrival &&
      (visit.colorClass === "海外出張" || visit.colorClass === "BESTA")
    ) {
      wbses.push(
        "SGP-" +
          formatDate(visit.estimatedTimeArrival, "YYYY") +
          "-" +
          formatDate(visit.estimatedTimeArrival, "MM")
      );
    }

    // ダウンロード
    dispatchReport.downloadServiceReports(imono, wbses);
  }

  /** その他資料ボタンクリック */
  function handleClickOtherFileButton(visit: VesselVisitsGetResponse) {
    const imono = visit.imoNo;
    const wbses = visit.wbses
      .flatMap((it) => it.wbs) // 配列の配列を1階層に変換
      .map((it) =>
        it && it.length >= 7
          ? it.substring(0, 3) + "-" + it.substring(3, 7)
          : ""
      ) // string|null を stringにするための変換
      .filter((it) => it); // 空文字を除去

    //wbesが空だったらシンガポール支社対応分か判定
    if (
      wbses.length === 0 &&
      visit.colorClass &&
      visit.estimatedTimeArrival &&
      (visit.colorClass === "海外出張" || visit.colorClass === "BESTA")
    ) {
      wbses.push(
        "SGP-" +
          formatDate(visit.estimatedTimeArrival, "YYYY") +
          "-" +
          formatDate(visit.estimatedTimeArrival, "MM")
      );
    }

    // ダウンロード
    dispatchReport.downloadOtherFiles(imono, wbses);
  }

  /** 一行分のレンダー */
  function renderOneRow(
    key: number,
    visitCell: JSX.Element,
    troubleCell: JSX.Element
  ) {
    return (
      <div className="row mt-2" key={key}>
        <div className="col-6">{visitCell}</div>
        <div className="col-6">{troubleCell}</div>
      </div>
    );
  }

  /** すべての行を描画するレンダー */
  function renderAllRows() {
    const elements: JSX.Element[] = [];

    const visits = getThisYearVisits();
    const troubles = getThisTroubles();

    // この年に表示する行数（訪船履歴と不具合対応の多いほう）
    const maxCount = Math.max(visits.length, troubles.length);
    if (maxCount === 0) {
      return renderOneRow(0, renderNoDataCell(), renderNoDataCell());
    }

    for (let i = 0; i < maxCount; i++) {
      // 訪船履歴
      const visit = visits.length > i ? visits[i] : undefined;
      const visitCell = visit ? (
        // データがあれば内容表示
        renderVisitCell(visit)
      ) : i === 0 ? (
        // データがなく1行目ならデータなし表示
        renderNoDataCell()
      ) : (
        // データがなく2行目以降なら何も表示しない
        <></>
      );

      // 不具合対応履歴
      const trouble = troubles.length > i ? troubles[i] : undefined;
      const troubleCell = trouble ? (
        // データがあれば内容表示
        renderTroubleCell(trouble)
      ) : i === 0 ? (
        // データがなく1行目ならデータなし表示
        renderNoDataCell()
      ) : (
        // データがなく2行目以降なら何も表示しない
        <></>
      );

      // 一行の表示内容を生成
      elements.push(renderOneRow(i, visitCell, troubleCell));
    }

    return <>{elements}</>;
  }

  /** 訪船履歴セルレンダー */
  function renderVisitCell(data: VesselVisitsGetResponse) {
    // 期間
    const period =
      formatDate(data.estimatedTimeArrival) +
      "～" +
      formatDate(data.estimatedTimeDeparture);
    // 人員
    const simplePersons: string[] = []; //  重複を除いた人員名の配列
    data.operations
      .flatMap((it) => it.persons) // "配列の配列"を1階層に変換
      .forEach((it) => {
        if (it && simplePersons.indexOf(it) < 0) {
          // 値が設定されている、かつ初出の場合
          simplePersons.push(it);
        }
      });
    const persons = simplePersons.join(" ");
    // WBS（1～3の後に4～5を改行でつなげる）
    const wbs13 = data.wbses
      .filter((_, index) => index < 3)
      .map((it) => it.wbs)
      .join(" ");
    const wbs45 = data.wbses
      .filter((_, index) => index >= 3)
      .map((it) => it.wbs)
      .join(" ");
    const wbs = wbs13 + (wbs45 ? "\n" + wbs45 : "");

    return (
      <div style={cellStyle}>
        <div style={rowStyle}>
          <div style={titleStyleVisit}>{tc("期間")}：</div>
          <div style={bodyStyle}>{period}</div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyleVisit}>{tc("場所")}：</div>
          <div style={bodyStyle}>{data.place}</div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyleVisit}>{tc("作業")}：</div>
          <div style={bodyStyle}>{data.operation}</div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyleVisit}>{tc("人員")}：</div>
          <div style={bodyStyle}>{persons}</div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyleVisit}>{tc("PJ番号")}：</div>
          <div style={{ ...bodyStyle, fontSize: "0.9rem" }}>{wbs}</div>
        </div>
        <div style={{ position: "absolute", right: "0", top: "5px" }}>
          <input
            type="button"
            className="btn b-btn-secondary btn-sm"
            style={data.serviceReportFlg ? {} : { visibility: "hidden" }}
            value={tc("サービスレポート")}
            onClick={() => handleClickServiceReportButton(data)}
          />
          <input
            type="button"
            className="btn b-btn-secondary btn-sm mx-2"
            value={tc("その他資料")}
            style={data.checkListFlg ? {} : { visibility: "hidden" }}
            onClick={() => handleClickOtherFileButton(data)}
          />
        </div>
      </div>
    );
  }

  /** 不具合対応履歴セルレンダー */
  function renderTroubleCell(data: VesselTroublesGetResponse) {
    // 閾値
    const MAX_LINE_COUNT = 3;
    // 閾値以下にした対応内容
    const troubleDetail = data.troubleDetail
      ?.split("\n")
      .filter((_, index) => index < MAX_LINE_COUNT)
      .join("\n");
    // moreを表示するかどうか
    const showsMoreLink =
      (data.troubleDetail?.split("\n").length ?? 0) > MAX_LINE_COUNT;

    return (
      <div style={cellStyle}>
        <div style={rowStyle}>
          <div style={titleStyle}>{tc("対応日")}：</div>
          <div style={bodyStyle}>{formatDate(data.troubleNoticeDate)}</div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyle}>{tc("製品情報")}：</div>
          <div style={bodyStyle}>{data.productInfo}</div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyle}>{tc("対応内容")}：</div>
          <div style={{ ...bodyStyle, position: "relative" }}>
            {troubleDetail}
            {showsMoreLink && (
              <div style={{ position: "absolute", right: "0", bottom: "0" }}>
                <a
                  href="#"
                  onClick={(e) => handleClickMoreLink(data.troubleDetail)}
                >
                  more...
                </a>
              </div>
            )}
          </div>
        </div>
        <div style={rowStyle}>
          <div style={titleStyle}>{tc("結果")}：</div>
          <div style={bodyStyle}>{data.result}</div>
        </div>
      </div>
    );
  }

  /** 対象データなしを表示するレンダー */
  function renderNoDataCell() {
    return <div style={cellStyle}>{tc("表示対象データがありません。")}</div>;
  }

  /** メインレンダー */
  return (
    <div>
      <div className="row">
        <div className="col-6">
          <label style={{ fontWeight: "bold" }}>{tc("訪船履歴")}</label>
        </div>
        <div className="col-6">
          <label style={{ fontWeight: "bold" }}>
            {tc("過去問い合わせ内容")}
          </label>
        </div>
      </div>
      {renderAllRows()}
    </div>
  );
}

export default MaintenanceHistoryListDataArea;
