import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import { useDialog } from "src/context/DialogProvider";
import { CompanyGetResponse } from "src/model/api/response/CompanyGetResponse";

import { useAuthUserContext } from "../../context/AuthUser";
import useApi from "../../hook/useApi";
import { CompanySuggestionsGetResponse } from "../../model/api/response/CompanySuggestionsGetResponse";
import { UserSuggestionsGetResponse } from "../../model/api/response/UserSuggestionsGetResponse";
import { VesselSuggestionsGetResponse } from "../../model/api/response/VesselSuggestionsGetResponse";
import {
  OrderFactor,
  OrderStatus,
  QuotationStatus,
  TextConst,
} from "../../util/Constant";
import { SelectOption, defaultSelectStyle } from "../../util/SelectUtil";
import {
  convertOrderStatusName,
  convertQuotationStatusName,
  formatDate,
  formatDateBetween,
  formatOrderNo,
  formatQuotationNo,
  isEnglish,
} from "../../util/ecUtil";
import RequireMark from "../common/RequireMark";
import TooltipIcon from "../common/TooltipIcon";
import CompanyDetail, { CompanyDetailHandles } from "../setting/CompanyDetail";
import UserDetail, {
  UserDetailHandles,
  statusBrowse,
} from "../setting/UserDetail";
import QuotationDetail, { QuotationDetailHandles } from "./QuotationDetail";
import QuotationOrderHistory, {
  QuotationOrderHistoryHandles,
} from "./QuotationOrderHistory";

export enum OrderDetailType {
  Quotation,
  Order,
}

export type OrderDetailChangeEvent = {
  name: string;
  value: string | number | boolean | undefined;
};
type Props = {
  readOnly: boolean;
  type: OrderDetailType;
  status: string;
  orderFactor: string;
  quotationNo?: number;
  quotationDateTime?: string;
  orderNo?: number;
  orderDateTime?: string;
  replyUserId?: number;
  replyUserName?: string;
  quotationCheckUserId?: number;
  quotationCheckUserName?: string;
  companyCode: string;
  companyName: string;
  orderUserId?: number;
  orderUserName?: string;
  vesselName: string;
  imoNo: string | undefined;
  vesselBuilder: string;
  yardNo: string;
  customerQuotationNo1?: string;
  customerQuotationNo2?: string;
  requestDeliveryDate: string;
  requestReplyDeadline: string;
  dispatchFlg: boolean;
  dispatchPlace: string;
  dispatchDate: string;
  replyInstallmentDeliveryFlg: boolean;
  /** 入渠ドック */
  dockName: string;
  /** 入渠ドック予定開始日 */
  dockScheduleStart: string;
  /** 入渠ドック予定終了日 */
  dockScheduleEnd: string;
  /** 顧客注文番号1 */
  customerOrderNo1?: string;
  /** 顧客注文番号2 */
  customerOrderNo2?: string;
  /** 不具合製品 */
  problemProduct: string;
  /** 不具合箇所 */
  problemDetail: string;
  /** 右ペイン */
  children?: React.ReactNode;
  /** 値変更イベント */
  onChange: (e: OrderDetailChangeEvent) => void;
  reQuotationFlg: boolean; // 再見積フラグ
  compQuotationFlg: boolean; // 完工見積フラグ
  isCompTempSave: boolean; // 完工見積一時保存フラグ
  fromQuotationDetail?: boolean; // 見積注文フラグ
  editableFileUpload?: boolean; // ファイルアップロード可否フラグ
  quotationOrderHistoryFlg?: boolean;
};

//主担当者１の型定義
type bemacMainCharge1 = {
  id: number | null;
  name: string | undefined;
};

function OrderDetailHeader(props: Props) {
  // ヘッダ部見出しの幅
  const itemTitleStyle = { width: "11rem" };
  // エリアごとのCSSクラス
  const areaClass = "p-1 bg-white b-detail-items";

  // API使用宣言
  const api = useApi();
  //ダイアログ使用宣言
  const showDialog = useDialog();
  // ユーザ情報
  const auth = useAuthUserContext();
  // メッセージ
  const { t } = useTranslation();
  // 翻訳
  const { t: tc } = useTranslation("OrderDetailHeader");
  // ユーザ詳細定義
  const userDetailRef = useRef<UserDetailHandles>(null);
  // 得意先詳細定義
  const companyDetailRef = useRef<CompanyDetailHandles>(null);
  // 見積詳細定義
  const quotationDetailRef = useRef<QuotationDetailHandles>(null);
  // 見積注文履歴定義
  const quotationOrderHistoryRef = useRef<QuotationOrderHistoryHandles>(null);

  //主担当者１
  const [bemacMainCharge1, setBemacMainCharge1] = useState<
    bemacMainCharge1 | undefined
  >(undefined);

  // 氏名コンボ
  const [customerUserOptions, setCustomerUserOptions] = useState<
    SelectOption<number>[]
  >([]);
  // 船名コンボ（船名キー入力ごとにAPI通信）
  const vesselLoadOptions = (inputValue: string) => {
    const companyCode = props.companyCode ?? "";
    const vesselName = encodeURIComponent(inputValue);
    return api
      .get(
        `/api/v1/vessel-suggestions?companyCode=${companyCode}&vesselName=${vesselName}`
      )
      .then((it) => {
        return it.data.map((row: VesselSuggestionsGetResponse) => {
          return {
            label: row.shipName,
            value: row.imoNo,
            appendix: { vesselBuilder: row.vesselBuilder, yardNo: row.yardNo },
          };
        });
      });
  };
  // 得意先コンボ（得意名キー入力ごとにAPI通信）
  const companyOptions = (inputValue: string) => {
    const companyName = encodeURIComponent(inputValue);
    return api
      .get(`/api/v1/company-suggestions?companyName=${companyName}`)
      .then((it) => {
        return it.data.map((row: CompanySuggestionsGetResponse) => {
          return {
            label: row.companyName,
            value: row.companyCode,
          };
        });
      });
  };
  // 初回レンダリングのみ実行
  useEffect(() => {
    if (showCustomerCombo()) {
      // 氏名コンボ
      refreshCustomerUserOptions(props.companyCode);
    }

    //主担当１を初期化
    setBemacMainCharge1(undefined);

    //得意先コードと見積回答ユーザIDが存在する＝ステータスが見積回答以降の時
    if (props.companyCode && props.replyUserId) {
      api
        .get<CompanyGetResponse>(
          `/api/v1/companies/${props.companyCode}?searchMode=0`
        )
        .then((response) => {
          const companyData = response.data.companyGetResponseSub1;
          // 主担当～アシスタントのいずれかに見積回答者が入っていればOK
          const staffs = [
            companyData.bemacMainCharge1Id,
            companyData.bemacMainCharge2Id,
            companyData.bemacSubCharge1Id,
            companyData.bemacSubCharge2Id,
            companyData.bemacAssistant1Id,
            companyData.bemacAssistant2Id,
          ];
          if (!staffs.includes(props.replyUserId!)) {
            // 見積回答者が主担当～アシスタントのどれでもなければBemac担当者の項目に主担当者と見積回答者を表示する
            setBemacMainCharge1({
              id: companyData.bemacMainCharge1Id,
              name: companyData.bemacMainCharge1,
            });
          }
        })
        .catch((error) => {
          showDialog({ error });
        });
    }
  }, []);

  // 種別の名称
  function factorToName(val: string) {
    // 分類値マスタを取得すると同期処理が必要になり、
    // 今回そこまでコーディングする必要は無いとして、直接変換しています。
    switch (val) {
      case OrderFactor.EC_PARTS:
        return tc("EC部品交換");
      case OrderFactor.LCM_PARTS:
        return tc("LCM部品交換");
      case OrderFactor.LCM_RECOMMEND:
        return tc("LCMレコメンド");
      default:
        return "";
    }
  }
  function isEc() {
    return props.orderFactor === OrderFactor.EC_PARTS;
  }
  function isLcm() {
    return props.orderFactor === OrderFactor.LCM_PARTS;
  }

  // ステータス名
  function statusName(val: string) {
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      const statusText = convertQuotationStatusName(val);
      return isEnglish() ? t("QuotationStatus." + statusText) : statusText;
    } else {
      // 注文の場合
      const statusText = convertOrderStatusName(val);
      return (
        (isEnglish() ? t("OrderStatus." + statusText) : statusText) +
        (val === OrderStatus.REQUESTED_CHANGE
          ? props.compQuotationFlg
            ? tc("(完工見積)")
            : ""
          : val === OrderStatus.COMPLETED
          ? props.isCompTempSave && auth.user().bemacFlg
            ? tc("(一時保存中)")
            : ""
          : val === OrderStatus.ORDERED &&
            props.isCompTempSave &&
            auth.user().bemacFlg
          ? tc("(一時保存中)")
          : "")
      );
    }
  }

  // BEMAC担当者表示判定
  function showsReplyUser() {
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      if (auth.user().bemacFlg) {
        // BEMACユーザの場合：全ステータス
        return true;
      } else {
        // 顧客ユーザの場合：回答確認中、成約、取消、否成約
        return [
          QuotationStatus.ANSWERED,
          QuotationStatus.ACCEPTED,
          QuotationStatus.DELETED,
          QuotationStatus.DENIED,
        ].includes(props.status);
      }
    } else {
      // 注文の場合
      if (auth.user().bemacFlg) {
        // BEMACユーザの場合：全ステータス
        return true;
      } else {
        // 顧客ユーザの場合：作成中、注文中以外
        return ![OrderStatus.DRAFT, OrderStatus.ORDERED].includes(props.status);
      }
    }
  }

  // 見積項目表示判定
  function showsQuotationItems() {
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      return true;
    } else {
      // 注文の場合
      return props.quotationNo ? true : false;
    }
  }

  // 注文項目表示判定
  function showsOrderItems() {
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      return false;
    } else {
      // 注文の場合
      return true;
    }
  }

  // 会社名コンボ表示判定
  function showCompanyCombo() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      if (auth.user().bemacFlg) {
        // BEMACユーザの場合：「作成中」かつECの場合
        return QuotationStatus.DRAFT === props.status && isEc();
      } else {
        // 顧客ユーザの場合：「作成中」「依頼中」の場合表示
        switch (props.status) {
          case QuotationStatus.DRAFT:
            return true;
          case QuotationStatus.REQUESTED:
            // ECの場合のみ表示
            return isEc();
          default:
            return false;
        }
      }
    } else {
      // 注文の場合
      if (auth.user().bemacFlg) {
        // BEMACユーザの場合：非表示
        return false;
      } else {
        // 顧客ユーザの場合：「作成中」の場合表示
        switch (props.status) {
          case OrderStatus.DRAFT:
            return true;
          default:
            return false;
        }
      }
    }
  }

  // 会社名コンボ活性判定
  function enableCompanyCombo() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    //見積かつEC以外(LCM/レコメンド)なら編集不可
    if (!isEc() && props.type === OrderDetailType.Quotation) {
      return false;
    }

    // BEMACユーザまたはスーパーユーザの場合
    return auth.user().bemacFlg || auth.user().suFlg;
  }

  // 氏名コンボ表示判定
  function showCustomerCombo() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }

    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合：BEMACかつ「作成中」の場合
      return auth.user().bemacFlg && QuotationStatus.DRAFT === props.status;
    } else {
      // 注文の場合、常に非表示
      return false;
    }
  }
  // 船舶コンボ入力可能判定
  function enableVesselCombo() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      switch (props.status) {
        case QuotationStatus.DRAFT:
          //ECのみ可
          return isEc();
        case QuotationStatus.REQUESTED:
          // 顧客かつECのみ、もしくはBEMACの場合可
          return (isEc() && !auth.user().bemacFlg) || auth.user().bemacFlg;
        default:
          return false;
      }
    } else {
      // 注文の場合
      switch (props.status) {
        case OrderStatus.DRAFT:
          // 作成中（＝顧客）は可
          return true;
        default:
          return false;
      }
    }
  }
  // ヘッダ部入力可能判定
  function enableHeaderItems() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合
      switch (props.status) {
        case QuotationStatus.DRAFT:
          // 顧客は可、BEMACはLCM以外可
          return !auth.user().bemacFlg || !isLcm();
        case QuotationStatus.REQUESTED:
          // 顧客は可
          return !auth.user().bemacFlg;
        default:
          return false;
      }
    } else {
      // 注文の場合
      switch (props.status) {
        case OrderStatus.DRAFT:
          // 作成中（＝顧客）は可
          return true;
        default:
          return false;
      }
    }
  }

  // 注文の場合、否認時(=注文変更後)に顧客注文番号の編集可能
  function enableHeaderCustomerNo() {
    if (props.type === OrderDetailType.Order) {
      if (
        !auth.user().bemacFlg &&
        props.status === OrderStatus.REQUESTED_CHANGE
      ) {
        return true;
      }
      return false;
    }
  }

  // ヘッダ部（技術者派遣系）入力可能判定
  function enableDispatchItems() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合：「作成中」「依頼中」の場合
      switch (props.status) {
        case QuotationStatus.DRAFT:
        case QuotationStatus.REQUESTED:
          // 顧客、BEMAC共に可
          return true;
        default:
          return false;
      }
    } else {
      // 注文の場合
      switch (props.status) {
        case OrderStatus.DRAFT:
          // 顧客は可、BEMACは不可
          return !auth.user().bemacFlg;
        case OrderStatus.ORDERED:
          // 顧客は不可、BEMACは可
          return auth.user().bemacFlg;
        default:
          return false;
      }
    }
  }
  // 分納入力可能判定
  function enableReplyInstallmentDeliveryFlg() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      if (isLcm()) {
        return false;
      }

      // 見積の場合：BEMACユーザかつ「作成中」「依頼中」の場合
      return (
        auth.user().bemacFlg &&
        [QuotationStatus.DRAFT, QuotationStatus.REQUESTED].includes(
          props.status
        )
      );
    } else {
      // 注文の場合
      switch (props.status) {
        case OrderStatus.DRAFT:
          // 顧客は可、BEMACは不可
          return !auth.user().bemacFlg;
        case OrderStatus.ORDERED:
          // 顧客は不可、BEMACは可
          return auth.user().bemacFlg;
        default:
          return false;
      }
    }
  }

  // ヘッダ部（不具合製品・不具合箇所）入力可能判定
  function enableProblematic() {
    if (props.readOnly) {
      // 読み取り専用モードの時は編集不可
      return false;
    }
    if (props.type === OrderDetailType.Quotation) {
      // 見積の場合：「作成中」「依頼中」の場合
      switch (props.status) {
        case QuotationStatus.DRAFT:
        case !auth.user().bemacFlg && QuotationStatus.REQUESTED:
          // 作成中は顧客、BEMAC共に可
          // 依頼中は顧客は可、BEMAC不可
          return true;
        default:
          return false;
      }
    } else {
      // 注文の場合
      switch (props.status) {
        case OrderStatus.DRAFT:
          // 顧客は可、BEMACは不可
          return !auth.user().bemacFlg;
        case OrderStatus.ORDERED:
          // 顧客は不可、BEMACは可
          return auth.user().bemacFlg;
        default:
          return false;
      }
    }
  }

  // 氏名コンボ再設定
  function refreshCustomerUserOptions(companyCode: string | undefined) {
    if (!companyCode) {
      setCustomerUserOptions([]);
      return;
    }
    const value = companyCode ?? "";
    api
      .get(
        `/api/v1/user-suggestions?companyCode=${value}&parentCompGetFlg=true`
      )
      .then((response) => {
        const users = response.data.map((row: UserSuggestionsGetResponse) => {
          return {
            label: row.userName,
            value: row.userId,
            delFlg: row.delFlg,
          };
        });
        setCustomerUserOptions(users);
      });
  }

  // テキストボックス変更
  function handleChangeInput(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "imoNoInput") {
      props.onChange({ name: "imoNo", value });
      const companyCode = props.companyCode ?? "";
      // 船舶情報検索
      if (value.length === 7) {
        api
          .get(
            `/api/v1/vessel-suggestions?companyCode=${companyCode}&imoNo=${value}`
          )
          .then((response) => {
            // 船舶情報検索（7文字入力された場合）
            setVesselInfo(response.data[0] || {});
          })
          .catch((error) => {
            // APIエラー時に空に設定
            setVesselInfo({}, true);
          });
      } else {
        // 入力値が7文字以外の場合、関連フィールドを空に設定
        setVesselInfo({}, true);
      }
    } else {
      // 元画面に連携
      props.onChange({ name, value });
    }
  }
  //船情報を空に設定
  function setVesselInfo(
    vessel: { vesselBuilder?: string; shipName?: string; yardNo?: string },
    isClear = false
  ) {
    props.onChange({
      name: "vesselBuilder",
      value: isClear ? "" : vessel.vesselBuilder || "",
    });
    props.onChange({
      name: "vesselName",
      value: isClear ? "" : vessel.shipName || "",
    });
    props.onChange({
      name: "yardNo",
      value: isClear ? "" : vessel.yardNo || "",
    });
  }
  // チェックボックス変更
  function handleChangeCheckbox(e: ChangeEvent<HTMLInputElement>) {
    const name = e.target.name;
    const value = e.target.checked;
    // 元画面に連携
    props.onChange({ name, value });
  }
  // コンボ変更
  function handleChangeSelect(
    name: string,
    e: SingleValue<SelectOption<string | number>>
  ) {
    const value = e?.value;
    // 元画面に連携
    props.onChange({ name, value });

    // 項目ごとの追加処理
    switch (name) {
      case "companyCode":
        // 会社コンボが変更されたら関連項目をクリアし氏名リストも取り直す
        props.onChange({ name: "companyName", value: e?.label });
        if (showCustomerCombo()) {
          props.onChange({ name: "orderUserId", value: undefined });
          props.onChange({ name: "orderUserName", value: undefined });
        }
        props.onChange({ name: "imoNo", value: undefined });
        props.onChange({ name: "vesselName", value: undefined });
        props.onChange({ name: "vesselBuilder", value: undefined });
        props.onChange({ name: "yardNo", value: undefined });
        refreshCustomerUserOptions(value?.toString());
        break;
      case "orderUserId":
        // 氏名コンボが変更されたらユーザ名も連携
        props.onChange({ name: "orderUserName", value: e?.label });
        break;
      case "imoNo":
        // 船名コンボが変更されたら船名、造船所も連携
        props.onChange({ name: "vesselName", value: e?.label });
        props.onChange({
          name: "vesselBuilder",
          value: e?.appendix.vesselBuilder,
        });
        props.onChange({ name: "yardNo", value: e?.appendix.yardNo });
        break;
    }
  }

  // 見積番号
  const quotationNo = formatQuotationNo(props.quotationNo);
  // 注文番号
  const orderNo = formatOrderNo(props.orderNo);

  return (
    <>
      <div className="row" style={{ marginTop: "26px", marginBottom: "26px" }}>
        <div className="col-4">
          <div className={areaClass}>
            <div className="input-group">
              <span
                className="b-input-group-text-light_blue py-0"
                style={itemTitleStyle}
              >
                {tc("種別")}
              </span>
              <div className="form-control py-0">
                <span style={{ color: "red" }} data-cy="種別">
                  {factorToName(props.orderFactor)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          {showsReplyUser() && (
            <div className={areaClass}>
              <div className="input-group">
                <span
                  className="b-input-group-text-light_blue py-0"
                  style={itemTitleStyle}
                >
                  {tc("BEMAC担当者")}
                </span>
                <span className="form-control py-0">
                  <a
                    href="#"
                    className="text-link"
                    onClick={(e) =>
                      userDetailRef.current?.show(
                        bemacMainCharge1
                          ? bemacMainCharge1.id!
                          : props.replyUserId,
                        statusBrowse,
                        true
                      )
                    }
                    data-cy="BEMAC担当者リンク"
                  >
                    {bemacMainCharge1
                      ? `${bemacMainCharge1.name}（${tc("代理")}：${
                          props.replyUserName
                        }）`
                      : props.replyUserName}
                  </a>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="col-4">
          <div className={areaClass}>
            <div className="input-group">
              <span
                className="b-input-group-text-light_blue py-0"
                style={itemTitleStyle}
              >
                {tc("ステータス")}
              </span>
              <div className="form-control py-0">
                <span style={{ color: "red" }} data-cy="ステータス">
                  {statusName(props.status)}
                </span>
              </div>
            </div>
            {showsQuotationItems() && (
              <div className="input-group py-0">
                <span
                  className="b-input-group-text-light_blue py-0"
                  style={itemTitleStyle}
                >
                  {tc("見積依頼日")}
                </span>
                <span className="form-control py-0" data-cy="見積依頼日">
                  {props.quotationDateTime
                    ? formatDate(props.quotationDateTime)
                    : "－"}
                </span>
              </div>
            )}
            {showsOrderItems() && (
              <div className="input-group">
                <span
                  className="b-input-group-text-light_blue py-0"
                  style={itemTitleStyle}
                >
                  {tc("発注日")}
                </span>
                <span className="form-control py-0" data-cy="発注日">
                  {props.orderDateTime ? formatDate(props.orderDateTime) : "－"}
                </span>
              </div>
            )}
            {showsQuotationItems() && (
              <div className="input-group">
                <div
                  className="b-input-group-text-light_blue py-0"
                  style={itemTitleStyle}
                >
                  {tc("見積番号")}
                </div>
                <span className="form-control py-0" data-cy="見積番号">
                  {quotationNo && !props.reQuotationFlg ? (
                    <>
                      {showsOrderItems() ? (
                        <a
                          href="#"
                          className="text-link"
                          onClick={(e) =>
                            quotationDetailRef.current?.show({
                              quotationNo: Number.parseInt(quotationNo),
                            })
                          }
                          data-cy="見積番号リンク"
                        >
                          {quotationNo}
                        </a>
                      ) : (
                        <>
                          {props.quotationOrderHistoryFlg ? (
                            quotationNo
                          ) : (
                            <>
                              {quotationNo}
                              <input
                                type="button"
                                value={tc("履歴")}
                                style={{
                                  fontSize: "0.8rem",
                                  marginLeft: "5rem",
                                }}
                                className="b-btn-light"
                                onClick={(e) =>
                                  quotationOrderHistoryRef.current?.show(
                                    Number.parseInt(quotationNo),
                                    true
                                  )
                                }
                                data-cy="履歴ボタン"
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    "－"
                  )}
                </span>
              </div>
            )}
            {showsOrderItems() && (
              <div className="input-group">
                <span
                  className="b-input-group-text-light_blue py-0"
                  style={itemTitleStyle}
                >
                  {tc("注文番号")}
                </span>
                <span className="form-control py-0" data-cy="注文番号">
                  {orderNo && !props.reQuotationFlg ? (
                    <>
                      {props.quotationOrderHistoryFlg ? (
                        orderNo
                      ) : (
                        <>
                          {orderNo}
                          <input
                            type="button"
                            value={tc("履歴")}
                            style={{ fontSize: "0.8rem", marginLeft: "5rem" }}
                            className="b-btn-light"
                            onClick={(e) =>
                              quotationOrderHistoryRef.current?.show(
                                Number.parseInt(orderNo),
                                false
                              )
                            }
                            data-cy="履歴ボタン"
                          />
                        </>
                      )}
                    </>
                  ) : (
                    "－"
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="row mt-2">
          <div className="col-4">
            <div className={areaClass}>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("会社名")}
                  {showCompanyCombo() && <RequireMark />}
                </span>
                <span className="form-control">
                  {showCompanyCombo() ? (
                    <>
                      {enableCompanyCombo() ? (
                        <span data-cy="会社名コンボ">
                          <AsyncSelect
                            styles={defaultSelectStyle}
                            placeholder={TextConst.COMBO_PLACEHOLDER}
                            loadOptions={companyOptions}
                            cacheOptions
                            defaultOptions
                            value={
                              props.companyName
                                ? {
                                    label: props.companyName,
                                    value: props.companyCode,
                                  }
                                : undefined
                            }
                            onChange={(e) => {
                              handleChangeSelect("companyCode", e);
                            }}
                          />
                        </span>
                      ) : (
                        <span className="m-1">
                          <a
                            href="#"
                            className="text-link"
                            onClick={(e) =>
                              companyDetailRef.current?.show(
                                props.companyCode,
                                true
                              )
                            }
                            data-cy="会社名リンク"
                          >
                            {props.companyName}
                          </a>
                        </span>
                      )}
                    </>
                  ) : (
                    <span className="m-1">
                      <a
                        href="#"
                        className="text-link"
                        onClick={(e) =>
                          companyDetailRef.current?.show(
                            props.companyCode,
                            true
                          )
                        }
                        data-cy="会社名リンク"
                      >
                        {props.companyName}
                      </a>
                    </span>
                  )}
                </span>
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("氏名")}
                  {showCustomerCombo() && <RequireMark />}
                </span>
                <span className="form-control">
                  {showCustomerCombo() ? (
                    <span data-cy="氏名コンボ">
                      <Select
                        styles={defaultSelectStyle}
                        placeholder={TextConst.COMBO_PLACEHOLDER}
                        options={customerUserOptions}
                        value={
                          props.orderUserId
                            ? {
                                label: props.orderUserName,
                                value: props.orderUserId,
                              }
                            : undefined
                        }
                        key={props.companyCode}
                        isDisabled={!auth.user().bemacFlg}
                        onChange={(e) => {
                          handleChangeSelect("orderUserId", e);
                        }}
                      />
                    </span>
                  ) : (
                    <span className="m-1">
                      <a
                        href="#"
                        className="text-link"
                        onClick={() =>
                          userDetailRef.current?.show(
                            props.orderUserId,
                            statusBrowse,
                            false
                          )
                        }
                        data-cy="氏名リンク"
                      >
                        {props.orderUserName}
                      </a>
                      {props.orderUserId !== props.quotationCheckUserId && props.quotationCheckUserId ? (
                      <a
                        href="#"
                        className="text-link"
                        onClick={() =>
                          userDetailRef.current?.show(
                            props.quotationCheckUserId,
                            statusBrowse,
                            false
                          )
                        }
                        data-cy="氏名リンク"
                      >
                     {`（${tc("代理")}：${props.quotationCheckUserName}）`}
                      </a>):("")
                    }
                    </span>
                  )}
                </span>
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("船名")}
                </span>
                {enableVesselCombo() ? (
                  <span className="form-control" data-cy="船名コンボ">
                    <AsyncSelect
                      styles={defaultSelectStyle}
                      placeholder={TextConst.COMBO_PLACEHOLDER}
                      isClearable
                      cacheOptions
                      defaultOptions
                      key={props.companyCode}
                      value={
                        props.imoNo && props.imoNo.length === 7 && props.vesselName
                          ? {
                              label: props.vesselName,
                              value: props.imoNo,
                            }
                          : null
                      }
                      loadOptions={vesselLoadOptions}
                      onChange={(e) => {
                        handleChangeSelect("imoNo", e);
                      }}
                    />
                  </span>
                ) : (
                  <span className="form-control" data-cy="船名">
                    {props.vesselName}
                  </span>
                )}
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("IMO番号")}
                </span>
                {enableVesselCombo() ? (
                  <input
                    type="text"
                    className="form-control"
                    name="imoNoInput"
                    value={props.imoNo}
                    maxLength={7}
                    onChange={handleChangeInput}
                    data-cy="IMO番号"
                  />
                ) : (
                  <span className="form-control" data-cy="IMO番号">
                    {props.imoNo}
                  </span>
                )}
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("建造造船所")}
                </span>
                <span className="form-control" data-cy="建造造船所">
                  {props.vesselBuilder}
                  {props.yardNo && "(" + props.yardNo + ")"}
                </span>
              </div>
              {/* 見積時は、顧客見積番号だけ表示する
                見積注文時は、顧客見積番号・顧客注文番号を表示する。顧客見積番号は表示するのみ（リンク等にはしない）
                見積無注文時は、顧客見積番号は非表示
              */}
              {(props.type === OrderDetailType.Quotation ||
                props.quotationNo) && (
                <div className="input-group">
                  <span className="b-input-group-text" style={itemTitleStyle}>
                    {tc("顧客見積番号")}
                  </span>
                  {/* 注文否認の場合、値あり */}
                  <input
                    type="text"
                    className="form-control"
                    name="customerQuotationNo1"
                    value={props.customerQuotationNo1}
                    maxLength={20}
                    disabled={
                      !enableHeaderItems() && !props.fromQuotationDetail
                    }
                    onChange={handleChangeInput}
                    data-cy="顧客見積番号１テキスト"
                  />
                  <input
                    type="text"
                    className="form-control"
                    name="customerQuotationNo2"
                    value={props.customerQuotationNo2}
                    maxLength={20}
                    disabled={
                      !enableHeaderItems() && !props.fromQuotationDetail
                    }
                    onChange={handleChangeInput}
                    data-cy="顧客見積番号２テキスト"
                  />
                </div>
              )}
              {props.type === OrderDetailType.Order && (
                <>
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("顧客注文番号")}
                    </span>
                    {/* 注文否認の場合、値あり */}
                    <input
                      type="text"
                      className="form-control"
                      name="customerOrderNo1"
                      value={props.customerOrderNo1}
                      maxLength={20}
                      disabled={
                        !enableHeaderItems() &&
                        !props.fromQuotationDetail &&
                        !enableHeaderCustomerNo()
                      }
                      onChange={handleChangeInput}
                      data-cy="顧客注文番号１テキスト"
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="customerOrderNo2"
                      value={props.customerOrderNo2}
                      maxLength={20}
                      disabled={
                        !enableHeaderItems() &&
                        !props.fromQuotationDetail &&
                        !enableHeaderCustomerNo()
                      }
                      onChange={handleChangeInput}
                      data-cy="顧客注文番号２テキスト"
                    />
                  </div>
                  {props.editableFileUpload && (
                    <span className="mt-2" style={{ color: "red" }}>
                      {tc(
                        "※注文書の添付は、ページ下部の『添付ファイル』よりお願いします。"
                      )}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-4">
            <div className={areaClass}>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("希望納期")}
                </span>
                <input
                  type="date"
                  className="form-control"
                  name="requestDeliveryDate"
                  value={formatDate(props.requestDeliveryDate, "YYYY-MM-DD")}
                  disabled={
                    (!enableHeaderItems() && !auth.user().bemacFlg) ||
                    auth.user().bemacFlg
                  }
                  onChange={handleChangeInput}
                  data-cy="希望納期テキスト"
                />
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("回答期限")}
                </span>
                <input
                  type="date"
                  className="form-control"
                  name="requestReplyDeadline"
                  value={formatDate(props.requestReplyDeadline, "YYYY-MM-DD")}
                  disabled={
                    (!enableHeaderItems() && !auth.user().bemacFlg) ||
                    auth.user().bemacFlg
                  }
                  onChange={handleChangeInput}
                  data-cy="回答期限テキスト"
                />
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("技術者派遣")}
                </span>
                <div
                  className={`form-check form-switch mb-0 form-control ${
                    !enableDispatchItems() || isLcm() ? "disabled" : ""
                  }`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.5",
                  }}
                >
                  <div>
                    <input
                      className="form-check-input"
                      style={{ marginLeft: "-2rem" }}
                      type="checkbox"
                      name="dispatchFlg"
                      checked={props.dispatchFlg}
                      disabled={!enableDispatchItems() || isLcm()}
                      onChange={handleChangeCheckbox}
                      data-cy="技術者派遣チェックボックス"
                    />
                    <span className="ms-5" data-cy="技術者派遣">
                      {props.dispatchFlg ? (
                        <span style={{ color: "red" }}>{tc("有")}</span>
                      ) : (
                        tc("無")
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {auth.user().bemacFlg &&
                enableDispatchItems() &&
                props.dispatchFlg && (
                  <div>
                    <span
                      className="ms-1"
                      style={{ color: "red" }}
                      data-cy="諸経費明細入力注記"
                    >
                      {tc("※諸経費の明細を入力してください。")}
                    </span>
                  </div>
                )}
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("技術者派遣場所")}
                  {enableDispatchItems() && props.dispatchFlg && (
                    <RequireMark />
                  )}
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="dispatchPlace"
                  value={props.dispatchPlace}
                  maxLength={100}
                  disabled={!(enableDispatchItems() && props.dispatchFlg)}
                  onChange={handleChangeInput}
                  data-cy="技術者派遣場所テキスト"
                />
              </div>
              {enableDispatchItems() && props.dispatchFlg && (
                <div>
                  <span
                    className="ms-1"
                    style={{ color: "red" }}
                    data-cy="技術者派遣場所注記"
                  >
                    {tc("※決定していない場合は、予定地を入力してください。")}
                  </span>
                </div>
              )}
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("技術者派遣希望日")}
                  {enableDispatchItems() && props.dispatchFlg && (
                    <RequireMark />
                  )}
                </span>
                <input
                  type="date"
                  className="form-control"
                  name="dispatchDate"
                  value={formatDate(props.dispatchDate, "YYYY-MM-DD")}
                  disabled={!(enableDispatchItems() && props.dispatchFlg)}
                  onChange={handleChangeInput}
                  data-cy="技術者派遣希望日テキスト"
                />
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("分納/後送")}
                </span>
                <div
                  className={`form-check form-switch mb-0 form-control ${
                    !enableReplyInstallmentDeliveryFlg() ? "disabled" : ""
                  }`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1.5",
                  }}
                >
                  <input
                    className="form-check-input"
                    style={{ marginLeft: "-2rem" }}
                    type="checkbox"
                    name="replyInstallmentDeliveryFlg"
                    checked={props.replyInstallmentDeliveryFlg}
                    disabled={!enableReplyInstallmentDeliveryFlg()}
                    onChange={handleChangeCheckbox}
                    data-cy="分納/後送チェックボックス"
                  />
                  <span className="ms-5" data-cy="分納/後送">
                    {props.replyInstallmentDeliveryFlg ? (
                      <span style={{ color: "red" }}>{tc("有")}</span>
                    ) : (
                      tc("無")
                    )}
                  </span>
                </div>
              </div>
              {/* LCMの場合のみ表示　ここから （入力されていたら表示する）*/}
              {[OrderFactor.LCM_PARTS, OrderFactor.LCM_RECOMMEND].includes(
                props.orderFactor
              ) && (
                <>
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("入渠ドック")}
                    </span>
                    <span className="form-control" data-cy="入渠ドック">
                      {props.dockName}
                    </span>
                  </div>
                  <div className="input-group">
                    <span className="b-input-group-text" style={itemTitleStyle}>
                      {tc("ドックスケジュール")}
                    </span>
                    <span className="form-control" data-cy="ドックスケジュール">
                      {formatDateBetween(
                        props.dockScheduleStart,
                        props.dockScheduleEnd
                      )}
                    </span>
                  </div>
                </>
              )}
              {/* LCMの場合のみ表示　ここまで */}
            </div>
          </div>
          {/* R3関連（アシスタントユーザ入力欄） */}
          <div className="col-4">{props.children}</div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className={areaClass}>
              {enableProblematic() && (
                <TooltipIcon messageId="T016"></TooltipIcon>
              )}
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("不具合製品")}
                </span>
                <input
                  type="text"
                  name="problemProduct"
                  className="form-control"
                  defaultValue={props.problemProduct}
                  maxLength={30}
                  disabled={!enableProblematic()}
                  onChange={handleChangeInput}
                  data-cy="不具合製品テキスト"
                />
              </div>
              <div className="input-group">
                <span className="b-input-group-text" style={itemTitleStyle}>
                  {tc("不具合箇所")}
                </span>
                <input
                  type="text"
                  name="problemDetail"
                  className="form-control"
                  defaultValue={props.problemDetail}
                  maxLength={30}
                  disabled={!enableProblematic()}
                  onChange={handleChangeInput}
                  data-cy="不具合箇所テキスト"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserDetail ref={userDetailRef} />
      <CompanyDetail ref={companyDetailRef} />
      <QuotationDetail ref={quotationDetailRef} />
      <QuotationOrderHistory ref={quotationOrderHistoryRef} />
    </>
  );
}

export default OrderDetailHeader;
