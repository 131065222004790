import axios from "axios";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { APP_VERSION } from "../App";
import { ApiCountContext } from "../context/ApiCountProvider";
import { useAuthUserContext } from "../context/AuthUser";
import { loadToken, saveExpirationTime, saveToken } from "../util/LoginUtil";
import { getBizError, getTimeZone, loadCurrentLang } from "../util/ecUtil";

const useApi = () => {
  const api = axios.create();
  const HEADER_AUTHORIZATION = "authorization";
  const HEADER_EXPIRATION_TIME = "x-b-expiration-time";
  const HEADER_LANGUAGE = "x-b-language";
  const HEADER_TIMEZONE = "x-b-time-zone";
  const LOGIN_API_URL = "/api/v1/login";
  const HEADER_APP_VERSION = "x-b-app-version";
  const apiCounter = useContext(ApiCountContext);

  const navigate = useNavigate();
  const auth = useAuthUserContext();

  api.interceptors.request.use((config) => {
    // リクエスト投げる時の共通処理
    apiCounter.increment();
    if (config.headers) {
      config.headers[HEADER_APP_VERSION] = APP_VERSION;
    }
    const token = loadToken();
    if (token && config.headers) {
      config.headers[HEADER_AUTHORIZATION] = token;
    }
    if (config.headers) {
      config.headers[HEADER_LANGUAGE] = loadCurrentLang();
      config.headers[HEADER_TIMEZONE] = getTimeZone();
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      apiCounter.decrement();
      if (response.headers[HEADER_AUTHORIZATION]) {
        // JWTトークンを保存
        const token = response.headers[HEADER_AUTHORIZATION];
        saveToken(token);
        // 有効時間を保存
        const expirationTime = response.headers[HEADER_EXPIRATION_TIME];
        saveExpirationTime(expirationTime);
        // // ユーザ情報を保存しなおす
        // const user = readUserInfoFromResponse(response);
        // auth.signin(user);
        // saveUserInfo(user);
      }
      return response;
    },
    (e) => {
      apiCounter.decrement();
      // エラー発生時の共通処理
      // ※ログイン画面から発行されるAPIは画面側でメッセージ表示するため除外
      if (!e.request.responseURL.endsWith(LOGIN_API_URL)) {
        // 認可エラー
        if (e.response.status === 401 || e.response.status === 403) {
          // 強制的にログイン画面に遷移
          auth.signout(() => {
            navigate("/login");
          });
          throw e;
        }
        const error = getBizError(e);
        if (error) {
          // システム利用時間外エラー
          if (error.id === "E040") {
            // 強制的にログイン画面に遷移
            auth.signout(() => {
              navigate("/login", { state: { initialMessage: error.text } });
            });
            throw e;
          }
        }
      }
      throw e;
    }
  );

  return api;
};

export default useApi;
