import { ColGroupDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { RefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUserContext } from "src/context/AuthUser";
import { useDialog } from "src/context/DialogProvider";
import useApi from "src/hook/useApi";
import { MaintenanceSchedulesGetResponse } from "src/model/api/response/MaintenanceSchedulesGetResponse";
import {
  QuotationDetailInitialValueParam,
  QuotationDetailInitialValueParamSub,
} from "src/model/param/QuotationDetailInitialValueParam";
import { agJapanCurrencyFormatter } from "src/util/AgGridUtil";
import { QuotationStatus } from "src/util/Constant";
import { SelectOption } from "src/util/SelectUtil";
import { isEnglish } from "src/util/ecUtil";

import {
  DONE,
  INSPECTION_DETAIL_BOTH,
  INSPECTION_DETAIL_INSPECTION,
  INSPECTION_DETAIL_PARTS,
  QUOTATION,
  RECOMMEND,
  TIMINGS,
  TIMING_MAIN,
  TIMING_SUBS,
} from "../../util/lcm/LcmConst";
import OrderDetail, { OrderDetailHandles } from "../order/OrderDetail";
import QuotationDetail, {
  QuotationDetailHandles,
} from "../order/QuotationDetail";
import {
  GridMaintenanceCostType,
  GridMaintenanceScheduleType,
  GridRecommendProductType,
  RowTypes,
} from "./RepairSchedule";

type Props = {
  completedYear: number;
  gridMaintenanceCostList: GridMaintenanceCostType[] | null;
  noRowsText: string;
  inspectionDetailOptions: SelectOption<string>[];
  maintenaceScheduleDataList: MaintenanceSchedulesGetResponse[];
  gridMaintenaceScheduleListInitial: GridMaintenanceScheduleType[];
  gridMaintenaceScheduleList: GridMaintenanceScheduleType[] | null;
  gridVesselProductList: GridRecommendProductType[] | null;
  companyCode: string | undefined;
  companyName: string | undefined;
  imoNo: string | undefined;
  vesselName: string | undefined;
  vesselBuilder: string | undefined;
  yardNo: string | undefined;

  gridRefMaintenanceCost: RefObject<AgGridReact<any>>;

  editableInspectionCostArea: () => boolean;
  editableRecommendProductsArea: () => boolean;
  handleClickSaveButton: (buttonName: string) => void;
  yearCheckValue: number;
};

/** 定期点検基本料金を表す品目コード群 */
const INSPECTION_COST_CODES: string = "ﾃﾝｹﾝﾘｮｳｷﾝ";
/** 作業タリフを表す品目コード群 */
const TARIFF_CODES: string = "ｻｷﾞｮｳﾋ";
/** 作業タリフ(平日時間外)を表す品目コード群 */
const TARIFF_CODES_WEEK_OUT: string = "ﾍｲｼﾞﾂｶﾞｲ";
/** 作業タリフ(休日)を表す品目コード群 */
const TARIFF_CODES_HOL: string = "ｷｭｳｼﾞﾂ";
/** 作業タリフ(休日時間外)を表す品目コード群 */
const TARIFF_CODES_HOL_OUT: string = "ｷｭｳｼﾞﾂｶﾞｲ";
/** 作業タリフ(海外)を表す品目コード群 */
const TARIFF_CODES_FOREIGN: string = "ｶｲｶﾞｲ";

function RepairScheduleMaintenanceCost(props: Props) {
  // 権限情報
  const auth = useAuthUserContext();
  // 参照
  const quotationDetailRef = useRef<QuotationDetailHandles>(null);
  const orderDetailRef = useRef<OrderDetailHandles>(null);

  // API使用宣言
  const api = useApi();
  // ダイアログ使用宣言
  const showDialog = useDialog();
  // 翻訳
  const { t: tc } = useTranslation("RepairScheduleMaintenanceCost");

  // 見積明細作成処理
  function generateQuotationDetails(inspectionYearClassValue: string) {
    // 該当年度における見積対象の部品を抽出
    const returnVal =
      props.gridVesselProductList
        ?.filter(
          (fil) =>
            (inspectionYearClassValue === "1" && fil.quotation1) ||
            (inspectionYearClassValue === "2" && fil.quotation2) ||
            (inspectionYearClassValue === "3" && fil.quotation3) ||
            (inspectionYearClassValue === "4" && fil.quotation4) ||
            (inspectionYearClassValue === "5" && fil.quotation5) ||
            (inspectionYearClassValue === "6" && fil.quotation6) ||
            (inspectionYearClassValue === "7" && fil.quotation7) ||
            (inspectionYearClassValue === "8" && fil.quotation8) ||
            (inspectionYearClassValue === "9" && fil.quotation9) ||
            (inspectionYearClassValue === "10" && fil.quotation10) ||
            (inspectionYearClassValue === "11" && fil.quotation11) ||
            (inspectionYearClassValue === "12" && fil.quotation12) ||
            (inspectionYearClassValue === "13" && fil.quotation13) ||
            (inspectionYearClassValue === "14" && fil.quotation14) ||
            (inspectionYearClassValue === "15" && fil.quotation15) ||
            (inspectionYearClassValue === "16" && fil.quotation16) ||
            (inspectionYearClassValue === "17" && fil.quotation17) ||
            (inspectionYearClassValue === "18" && fil.quotation18) ||
            (inspectionYearClassValue === "19" && fil.quotation19) ||
            (inspectionYearClassValue === "20" && fil.quotation20) ||
            (inspectionYearClassValue === "21" && fil.quotation21) ||
            (inspectionYearClassValue === "22" && fil.quotation22) ||
            (inspectionYearClassValue === "23" && fil.quotation23) ||
            (inspectionYearClassValue === "24" && fil.quotation24) ||
            (inspectionYearClassValue === "25" && fil.quotation25) ||
            (inspectionYearClassValue === "26" && fil.quotation26) ||
            (inspectionYearClassValue === "27" && fil.quotation27) ||
            (inspectionYearClassValue === "28" && fil.quotation28) ||
            (inspectionYearClassValue === "29" && fil.quotation29) ||
            (inspectionYearClassValue === "30" && fil.quotation30)
        )
        .map((it) => {
          return {
            referenceDetailNo: Number(it.rowId),
            productCode: it.productCode ?? "",
            productGroupName: it.productGroupName ?? "",
            productGroupNameEn: it.productGroupName ?? "",
            spec: it.spec ?? "",
            quantity: it.recommendQuantity ?? 0,
          } as QuotationDetailInitialValueParamSub;
        }) ?? [];

    // 該当年度におけるメンテナンススケジュール(定期点検・部品交換情報を参照)
    const maintenance = props.gridMaintenaceScheduleList?.find(
      (it) => it.timing === Number(inspectionYearClassValue)
    );

    // 点検内訳に応じた明細の追加
    if (maintenance && maintenance.inspectionDetailClassValue) {
      if (
        maintenance.inspectionDetailClassValue === INSPECTION_DETAIL_BOTH ||
        maintenance.inspectionDetailClassValue === INSPECTION_DETAIL_INSPECTION
      ) {
        // 両方、もしくは定期点検のみの場合は定期点検基本料金の明細を追加
        returnVal.push({
          referenceDetailNo: -1,
          productCode: INSPECTION_COST_CODES,
          quantity: 1,
          unitPrice: maintenance.inspectionCost
            ? Number(maintenance.inspectionCost)
            : 0,
        });
      }

      if (
        maintenance.inspectionDetailClassValue === INSPECTION_DETAIL_BOTH ||
        maintenance.inspectionDetailClassValue === INSPECTION_DETAIL_PARTS
      ) {
        // 両方、もしくは定期点検のみの場合は作業タリフの明細を追加
        if (maintenance.planInspectionTime && maintenance.planTariffPerHour) {
          returnVal.push({
            referenceDetailNo: -1,
            productCode: TARIFF_CODES,
            quantity: maintenance.planInspectionTime
              ? Number(maintenance.planInspectionTime)
              : 0,
            unitPrice: maintenance.planTariffPerHour
              ? Number(maintenance.planTariffPerHour)
              : 0,
          });
        }

        // 作業タリフ(平日時間外)
        if (
          maintenance.planInspectionTimeWeekOut &&
          maintenance.planTariffPerHourWeekOut
        ) {
          returnVal.push({
            referenceDetailNo: -1,
            productCode: TARIFF_CODES_WEEK_OUT,
            quantity: maintenance.planInspectionTimeWeekOut
              ? Number(maintenance.planInspectionTimeWeekOut)
              : 0,
            unitPrice: maintenance.planTariffPerHourWeekOut
              ? Number(maintenance.planTariffPerHourWeekOut)
              : 0,
          });
        }

        // 作業タリフ(休日)
        if (
          maintenance.planInspectionTimeHol &&
          maintenance.planTariffPerHourHol
        ) {
          returnVal.push({
            referenceDetailNo: -1,
            productCode: TARIFF_CODES_HOL,
            quantity: maintenance.planInspectionTimeHol
              ? Number(maintenance.planInspectionTimeHol)
              : 0,
            unitPrice: maintenance.planTariffPerHourHol
              ? Number(maintenance.planTariffPerHourHol)
              : 0,
          });
        }

        if (
          maintenance.planInspectionTimeHolOut &&
          maintenance.planTariffPerHourHolOut
        ) {
          returnVal.push({
            referenceDetailNo: -1,
            productCode: TARIFF_CODES_HOL_OUT,
            quantity: maintenance.planInspectionTimeHolOut
              ? Number(maintenance.planInspectionTimeHolOut)
              : 0,
            unitPrice: maintenance.planTariffPerHourHolOut
              ? Number(maintenance.planTariffPerHourHolOut)
              : 0,
          });
        }

        if (
          maintenance.planInspectionTimeForeign &&
          maintenance.planTariffPerHourForeign
        ) {
          returnVal.push({
            referenceDetailNo: -1,
            productCode: TARIFF_CODES_FOREIGN,
            quantity: maintenance.planInspectionTimeForeign
              ? Number(maintenance.planInspectionTimeForeign)
              : 0,
            unitPrice: maintenance.planTariffPerHourForeign
              ? Number(maintenance.planTariffPerHourForeign)
              : 0,
          });
        }
      }
    }

    return returnVal;
  }

  //見積番号リンク押下時の処理
  function onClickQuotationLink(
    inspectionYearClassValue: string,
    quotationNo?: number,
    quotationStatusClassValue?: string
  ) {
    // メンテナンススケジュールの変更チェック
    if (
      JSON.stringify(props.gridMaintenaceScheduleListInitial) !==
      JSON.stringify(props.gridMaintenaceScheduleList)
    ) {
      showDialog({ id: "E079" });
      return;
    }

    if (quotationNo === undefined) {
      const maintenanceSchedule = props.gridMaintenaceScheduleList?.find(
        (it) => it.timing === Number(inspectionYearClassValue)
      );
      const param: QuotationDetailInitialValueParam = {
        // 見積画面に渡すパラメータを組み立てる
        companyCode: props?.companyCode ?? "",
        companyName: props?.companyName ?? "",
        imoNo: props?.imoNo ?? "",
        vesselName: props?.vesselName ?? "",
        vesselBuilder: props?.vesselBuilder ?? "",
        yardNo: props?.yardNo ?? "",
        dispatchFlg: true,
        dockName: maintenanceSchedule?.dockName ?? "",
        dockScheduleStart: maintenanceSchedule?.dockScheduleStart ?? "",
        dockScheduleEnd: maintenanceSchedule?.dockScheduleEnd ?? "",
        details: generateQuotationDetails(inspectionYearClassValue),
      };
      quotationDetailRef.current?.show({
        initialData: param,
        maintenanceScheduleKey: {
          companyCode: props?.companyCode ?? "",
          imoNo: props?.imoNo ?? "",
          inspectionYearClassValue: inspectionYearClassValue,
        },
      });
    } else {
      // 該当の見積が存在するか確認
      // 見積番号が設定されていたらAPIから初期化する
      api
        .get(`/api/v1/quotations/${quotationNo}`)
        .then((response) => {
          // 見積依頼中且つBEMACユーザの場合は、更新内容のデータも渡す
          if (
            props.editableRecommendProductsArea() &&
            quotationStatusClassValue &&
            (quotationStatusClassValue === QuotationStatus.REQUESTED ||
              quotationStatusClassValue === QuotationStatus.DRAFT)
          ) {
            const param: QuotationDetailInitialValueParam = {
              // 見積画面に渡すパラメータを組み立てる,
              details: generateQuotationDetails(inspectionYearClassValue),
            };
            quotationDetailRef.current?.show({
              quotationNo: quotationNo,
              initialData: param,
              maintenanceScheduleKey: {
                companyCode: props?.companyCode ?? "",
                imoNo: props?.imoNo ?? "",
                inspectionYearClassValue: inspectionYearClassValue,
              },
            });
          } else {
            quotationDetailRef.current?.show({
              quotationNo: quotationNo,
            });
          }
        })
        .catch((error) => {
          // エラー時の処理
          if (error.response.status === 404) {
            //エラーメッセージの表示
            showDialog({ id: "E077" });
          } else {
            showDialog({ error });
          }
        });
    }
  }

  //注文番号リンク押下時の処理
  function onClickOrderLink(orderNo: number) {
    // 該当の注文が存在するか確認
    // 見積番号が設定されていたらAPIから初期化する
    api
      .get(`/api/v1/orders/${orderNo}`)
      .then((response) => {
        orderDetailRef.current?.show({ orderNo: orderNo });
      })
      .catch((error) => {
        // エラー時の処理
        if (error.response.status === 404) {
          //エラーメッセージの表示
          showDialog({ id: "E078" });
        } else {
          showDialog({ error });
        }
      });
  }

  // 定期点検金額グリッドのセルレンダラー
  function cellRenderSelectorMaintenanceCost(params: ICellRendererParams) {
    switch (params.data.rowType) {
      case RowTypes.InspectionDetail:
        return { component: inspectionDetailCell };
      case RowTypes.QuotationOrder:
        return { component: quotationOrderCell };
      default:
        break;
    }
  }

  // 点検内訳行のセルのレンダリング
  function inspectionDetailCell(params: ICellRendererParams) {
    if (params.data.rowType === RowTypes.InspectionDetail) {
      if (params.column?.getColId() === "recommend1") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend1
        )?.label;
      } else if (params.column?.getColId() === "recommend2") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend2
        )?.label;
      } else if (params.column?.getColId() === "recommend3") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend3
        )?.label;
      } else if (params.column?.getColId() === "recommend4") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend4
        )?.label;
      } else if (params.column?.getColId() === "recommend5") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend5
        )?.label;
      } else if (params.column?.getColId() === "recommend6") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend6
        )?.label;
      } else if (params.column?.getColId() === "recommend7") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend7
        )?.label;
      } else if (params.column?.getColId() === "recommend8") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend8
        )?.label;
      } else if (params.column?.getColId() === "recommend9") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend9
        )?.label;
      } else if (params.column?.getColId() === "recommend10") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend10
        )?.label;
      } else if (params.column?.getColId() === "recommend11") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend11
        )?.label;
      } else if (params.column?.getColId() === "recommend12") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend12
        )?.label;
      } else if (params.column?.getColId() === "recommend13") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend13
        )?.label;
      } else if (params.column?.getColId() === "recommend14") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend14
        )?.label;
      } else if (params.column?.getColId() === "recommend15") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend15
        )?.label;
      } else if (params.column?.getColId() === "recommend16") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend16
        )?.label;
      } else if (params.column?.getColId() === "recommend17") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend17
        )?.label;
      } else if (params.column?.getColId() === "recommend18") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend18
        )?.label;
      } else if (params.column?.getColId() === "recommend19") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend19
        )?.label;
      } else if (params.column?.getColId() === "recommend20") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend20
        )?.label;
      } else if (params.column?.getColId() === "recommend21") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend21
        )?.label;
      } else if (params.column?.getColId() === "recommend22") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend22
        )?.label;
      } else if (params.column?.getColId() === "recommend23") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend23
        )?.label;
      } else if (params.column?.getColId() === "recommend24") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend24
        )?.label;
      } else if (params.column?.getColId() === "recommend25") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend25
        )?.label;
      } else if (params.column?.getColId() === "recommend26") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend26
        )?.label;
      } else if (params.column?.getColId() === "recommend27") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend27
        )?.label;
      } else if (params.column?.getColId() === "recommend28") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend28
        )?.label;
      } else if (params.column?.getColId() === "recommend29") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend29
        )?.label;
      } else if (params.column?.getColId() === "recommend30") {
        return props.inspectionDetailOptions.find(
          (it) => it.value === params.data.recommend30
        )?.label;
      } else {
        return undefined;
      }
    }
  }

  // 見積・注文行のセルのレンダリング
  function quotationOrderCell(params: ICellRendererParams) {
    // 注文登録ユーザID
    var orderCreateUserId = undefined;

    // 点検のタイプの判定
    if (params.column?.getColId().includes(DONE)) {
      // 実施列の場合
      let orderNo: number | undefined = undefined;
      let inspectionYearClassValue: string;

      if (params.column?.getColId() === DONE + "1") {
        inspectionYearClassValue = "1";
      } else if (params.column?.getColId() === DONE + "2") {
        inspectionYearClassValue = "2";
      } else if (params.column?.getColId() === DONE + "3") {
        inspectionYearClassValue = "3";
      } else if (params.column?.getColId() === DONE + "4") {
        inspectionYearClassValue = "4";
      } else if (params.column?.getColId() === DONE + "5") {
        inspectionYearClassValue = "5";
      } else if (params.column?.getColId() === DONE + "6") {
        inspectionYearClassValue = "6";
      } else if (params.column?.getColId() === DONE + "7") {
        inspectionYearClassValue = "7";
      } else if (params.column?.getColId() === DONE + "8") {
        inspectionYearClassValue = "8";
      } else if (params.column?.getColId() === DONE + "9") {
        inspectionYearClassValue = "9";
      } else if (params.column?.getColId() === DONE + "10") {
        inspectionYearClassValue = "10";
      } else if (params.column?.getColId() === DONE + "11") {
        inspectionYearClassValue = "11";
      } else if (params.column?.getColId() === DONE + "12") {
        inspectionYearClassValue = "12";
      } else if (params.column?.getColId() === DONE + "13") {
        inspectionYearClassValue = "13";
      } else if (params.column?.getColId() === DONE + "14") {
        inspectionYearClassValue = "14";
      } else if (params.column?.getColId() === DONE + "15") {
        inspectionYearClassValue = "15";
      } else if (params.column?.getColId() === DONE + "16") {
        inspectionYearClassValue = "16";
      } else if (params.column?.getColId() === DONE + "17") {
        inspectionYearClassValue = "17";
      } else if (params.column?.getColId() === DONE + "18") {
        inspectionYearClassValue = "18";
      } else if (params.column?.getColId() === DONE + "19") {
        inspectionYearClassValue = "19";
      } else if (params.column?.getColId() === DONE + "20") {
        inspectionYearClassValue = "20";
      } else if (params.column?.getColId() === DONE + "21") {
        inspectionYearClassValue = "21";
      } else if (params.column?.getColId() === DONE + "22") {
        inspectionYearClassValue = "22";
      } else if (params.column?.getColId() === DONE + "23") {
        inspectionYearClassValue = "23";
      } else if (params.column?.getColId() === DONE + "24") {
        inspectionYearClassValue = "24";
      } else if (params.column?.getColId() === DONE + "25") {
        inspectionYearClassValue = "25";
      } else if (params.column?.getColId() === DONE + "26") {
        inspectionYearClassValue = "26";
      } else if (params.column?.getColId() === DONE + "27") {
        inspectionYearClassValue = "27";
      } else if (params.column?.getColId() === DONE + "28") {
        inspectionYearClassValue = "28";
      } else if (params.column?.getColId() === DONE + "29") {
        inspectionYearClassValue = "29";
      } else if (params.column?.getColId() === DONE + "30") {
        inspectionYearClassValue = "30";
      }

      // 該当年度におけるメンテナンススケジュール(定期点検・部品交換情報を暗唱)
      const maintenance = props.maintenaceScheduleDataList.find(
        (it) => it.inspectionYearClassValue === inspectionYearClassValue
      );

      // 点検内訳に応じた明細の追加
      if (maintenance) {
        orderNo = Number(maintenance.orderNo);
        orderCreateUserId = Number(maintenance.orderCreateUserId);
      }

      if (orderNo) {
        return (
          <>
            {orderCreateUserId !== -1000 && (
              <a
                href="#"
                className="text-link"
                onClick={() => onClickOrderLink(Number(orderNo))}
                data-cy="注文内容リンク"
              >
                {tc("注文内容")}
              </a>
            )}
          </>
        );
      } else {
        return undefined;
      }
    } else if (params.column?.getColId().includes(QUOTATION)) {
      // 見積列の場合
      let quotationNo: number | undefined;
      let quotationStatusClassValue: string | undefined;
      let inspectionYearClassValue: string;

      if (params.column?.getColId() === QUOTATION + "1") {
        inspectionYearClassValue = "1";
      } else if (params.column?.getColId() === QUOTATION + "2") {
        inspectionYearClassValue = "2";
      } else if (params.column?.getColId() === QUOTATION + "3") {
        inspectionYearClassValue = "3";
      } else if (params.column?.getColId() === QUOTATION + "4") {
        inspectionYearClassValue = "4";
      } else if (params.column?.getColId() === QUOTATION + "5") {
        inspectionYearClassValue = "5";
      } else if (params.column?.getColId() === QUOTATION + "6") {
        inspectionYearClassValue = "6";
      } else if (params.column?.getColId() === QUOTATION + "7") {
        inspectionYearClassValue = "7";
      } else if (params.column?.getColId() === QUOTATION + "8") {
        inspectionYearClassValue = "8";
      } else if (params.column?.getColId() === QUOTATION + "9") {
        inspectionYearClassValue = "9";
      } else if (params.column?.getColId() === QUOTATION + "10") {
        inspectionYearClassValue = "10";
      } else if (params.column?.getColId() === QUOTATION + "11") {
        inspectionYearClassValue = "11";
      } else if (params.column?.getColId() === QUOTATION + "12") {
        inspectionYearClassValue = "12";
      } else if (params.column?.getColId() === QUOTATION + "13") {
        inspectionYearClassValue = "13";
      } else if (params.column?.getColId() === QUOTATION + "14") {
        inspectionYearClassValue = "14";
      } else if (params.column?.getColId() === QUOTATION + "15") {
        inspectionYearClassValue = "15";
      } else if (params.column?.getColId() === QUOTATION + "16") {
        inspectionYearClassValue = "16";
      } else if (params.column?.getColId() === QUOTATION + "17") {
        inspectionYearClassValue = "17";
      } else if (params.column?.getColId() === QUOTATION + "18") {
        inspectionYearClassValue = "18";
      } else if (params.column?.getColId() === QUOTATION + "19") {
        inspectionYearClassValue = "19";
      } else if (params.column?.getColId() === QUOTATION + "20") {
        inspectionYearClassValue = "20";
      } else if (params.column?.getColId() === QUOTATION + "21") {
        inspectionYearClassValue = "21";
      } else if (params.column?.getColId() === QUOTATION + "22") {
        inspectionYearClassValue = "22";
      } else if (params.column?.getColId() === QUOTATION + "23") {
        inspectionYearClassValue = "23";
      } else if (params.column?.getColId() === QUOTATION + "24") {
        inspectionYearClassValue = "24";
      } else if (params.column?.getColId() === QUOTATION + "25") {
        inspectionYearClassValue = "25";
      } else if (params.column?.getColId() === QUOTATION + "26") {
        inspectionYearClassValue = "26";
      } else if (params.column?.getColId() === QUOTATION + "27") {
        inspectionYearClassValue = "27";
      } else if (params.column?.getColId() === QUOTATION + "28") {
        inspectionYearClassValue = "28";
      } else if (params.column?.getColId() === QUOTATION + "29") {
        inspectionYearClassValue = "29";
      } else if (params.column?.getColId() === QUOTATION + "30") {
        inspectionYearClassValue = "30";
      }

      // 該当年度におけるメンテナンススケジュール(定期点検・部品交換情報を暗唱)
      const maintenance = props.maintenaceScheduleDataList.find(
        (it) => it.inspectionYearClassValue === inspectionYearClassValue
      );

      // 点検内訳に応じた明細の追加
      if (maintenance) {
        quotationNo = Number(maintenance.quotationNo);
        quotationStatusClassValue = maintenance.quotationStatusClassValue;
        orderCreateUserId = Number(maintenance.orderCreateUserId);
        if (
          !auth.user().bemacFlg &&
          maintenance?.quotationStatusClassValue === QuotationStatus.DRAFT
        ) {
          return <></>;
        } else if (quotationNo) {
          return (
            <>
              {orderCreateUserId !== -1000 && (
                <a
                  href="#"
                  className="text-link"
                  onClick={() =>
                    onClickQuotationLink(
                      inspectionYearClassValue,
                      quotationNo,
                      quotationStatusClassValue
                    )
                  }
                  data-cy="見積内容リンク"
                >
                  {tc("見積内容")}
                </a>
              )}
            </>
          );
        } else {
          return (
            <>
              {props.editableInspectionCostArea() &&
                orderCreateUserId !== -1000 && (
                  <a
                    href="#"
                    className="text-link"
                    onClick={() =>
                      onClickQuotationLink(inspectionYearClassValue)
                    }
                    data-cy="見積内容リンク"
                  >
                    {tc("見積内容")}
                  </a>
                )}
            </>
          );
        }
      } else {
        return <></>;
      }
    } else {
      return undefined;
    }
  }

  // 定期点検金額グリッドの列定義
  const columnDefsMaintenanceCost: ColGroupDef[] = [
    {
      headerName: tc("実施年度"),
      children: [
        {
          headerName: tc("実施状況"),
          field: "rowTitle",
          colId: "rowTitle",
          width: isEnglish() ? 170 : 150,
          lockPosition: "left",
          headerClass: ["b-cell-border-right-solid-thin-gray"],
          cellClass: [
            "b-cell-border-right-solid-thin-gray",
            "b-grid-cell-multi-line",
          ],
          pinned: true,
          cellRenderer: (params: any) => {
            return <>{tc(params.value)}</>;
          },
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    },
  ];

  (props.yearCheckValue === 0 ? TIMING_MAIN : TIMINGS).forEach((it) => {
    columnDefsMaintenanceCost.push({
      headerName:
        props.yearCheckValue === 0
          ? (TIMING_SUBS.includes(it) ? it + 0.5 : it) +
            tc("年(") +
            (props.completedYear + it) +
            tc("年)")
          : it + tc("年(") + (props.completedYear + it) + tc("年)"),
      children: [
        {
          headerName: tc("予定"),
          field: RECOMMEND + it,
          colId: RECOMMEND + it,
          width: 200,
          lockPosition: "left",
          headerClass: ["b-cell-border-right-solid-thin-gray"],
          cellClass: ["b-cell-border-right-solid-thin-gray"],
          cellRendererSelector: cellRenderSelectorMaintenanceCost,
          colSpan: (params) => {
            if (params.data.rowType === RowTypes.InspectionDetail) {
              return 3;
            } else {
              return 1;
            }
          },
          cellClassRules: {
            "text-end": (params: any) =>
              params.data.rowType !== RowTypes.InspectionDetail,
            "text-center": (params: any) =>
              params.data.rowType === RowTypes.InspectionDetail,
          },
          valueFormatter: agJapanCurrencyFormatter,
        },
        {
          headerName: tc("実施"),
          field: DONE + it,
          colId: DONE + it,
          width: 200,
          lockPosition: "left",
          headerClass: ["b-cell-border-right-solid-thin-gray"],
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
          cellRendererSelector: cellRenderSelectorMaintenanceCost,
          cellClassRules: {
            "text-end": (params: any) =>
              params.data.rowType !== RowTypes.InspectionDetail,
            "text-center": (params: any) =>
              params.data.rowType === RowTypes.QuotationOrder,
          },
          valueFormatter: agJapanCurrencyFormatter,
        },
        {
          headerName: tc("見積"),
          field: QUOTATION + it,
          colId: QUOTATION + it,
          width: 200,
          lockPosition: "left",
          headerClass: ["b-cell-border-right-solid-thin-gray"],
          cellClass: ["b-cell-border-right-solid-thin-gray", "text-end"],
          cellRendererSelector: cellRenderSelectorMaintenanceCost,
          cellClassRules: {
            "text-end": (params: any) =>
              params.data.rowType !== RowTypes.InspectionDetail,
            "text-center": (params: any) =>
              params.data.rowType === RowTypes.QuotationOrder,
          },
          valueGetter: (params) => {
            // 実績移行データの場合は非表示にする
            const maintenance = props.maintenaceScheduleDataList.find(
              (row) => row.inspectionYearClassValue === String(it)
            );
            if (maintenance?.orderCreateUserId === -1000) {
              return "";
            } else {
              if (it === 1) {
                return params.data.quotation1;
              } else if (it === 2) {
                return params.data.quotation2;
              } else if (it === 3) {
                return params.data.quotation3;
              } else if (it === 4) {
                return params.data.quotation4;
              } else if (it === 5) {
                return params.data.quotation5;
              } else if (it === 6) {
                return params.data.quotation6;
              } else if (it === 7) {
                return params.data.quotation7;
              } else if (it === 8) {
                return params.data.quotation8;
              } else if (it === 9) {
                return params.data.quotation9;
              } else if (it === 10) {
                return params.data.quotation10;
              } else if (it === 11) {
                return params.data.quotation11;
              } else if (it === 12) {
                return params.data.quotation12;
              } else if (it === 13) {
                return params.data.quotation13;
              } else if (it === 14) {
                return params.data.quotation14;
              } else if (it === 15) {
                return params.data.quotation15;
              } else if (it === 16) {
                return params.data.quotation16;
              } else if (it === 17) {
                return params.data.quotation17;
              } else if (it === 18) {
                return params.data.quotation18;
              } else if (it === 19) {
                return params.data.quotation19;
              } else if (it === 20) {
                return params.data.quotation20;
              } else if (it === 21) {
                return params.data.quotation21;
              } else if (it === 22) {
                return params.data.quotation22;
              } else if (it === 23) {
                return params.data.quotation23;
              } else if (it === 24) {
                return params.data.quotation24;
              } else if (it === 25) {
                return params.data.quotation25;
              } else if (it === 26) {
                return params.data.quotation26;
              } else if (it === 27) {
                return params.data.quotation27;
              } else if (it === 28) {
                return params.data.quotation28;
              } else if (it === 29) {
                return params.data.quotation29;
              } else if (it === 30) {
                return params.data.quotation30;
              }
            }
          },
          valueFormatter: agJapanCurrencyFormatter,
        },
      ],
      headerClass: ["b-cell-border-right-solid-thin-gray"],
    });
  });

  return (
    <>
      <div className="mt-3">
        <span className="mx-3" data-cy="点検金額">
          {tc("点検金額")}
        </span>
        <div
          className={
            "ag-theme-alpine mx-auto position-relative mt-2 b-header-row-white"
          }
          data-cy="点検金額グリッド"
        >
          <AgGridReact
            domLayout="autoHeight"
            columnDefs={columnDefsMaintenanceCost}
            rowData={props.gridMaintenanceCostList}
            ref={props.gridRefMaintenanceCost}
            overlayNoRowsTemplate={props.noRowsText}
          />
        </div>
        <QuotationDetail
          ref={quotationDetailRef}
          onClickSaveButton={props.handleClickSaveButton}
        />
        <OrderDetail
          ref={orderDetailRef}
          onClickSaveButton={props.handleClickSaveButton}
        />
      </div>
    </>
  );
}

export default RepairScheduleMaintenanceCost;
