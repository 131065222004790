import "@fontsource/roboto";

import "./App.css";
import LoadingSpinner from "./component/common/LoadingSpinner";
import { Providers } from "./context/Provider";
import "./i18n";
import RouteConfig from "./route/RouteConfig";

export const APP_VERSION = "3.1.0";

function App() {
  return (
    <>
      <Providers>
        <LoadingSpinner />
        <RouteConfig />
      </Providers>
    </>
  );
}

export default App;
